import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { domainImage } from '../../../data/const';
import { TypeTram } from '../../../data/const';
import { Branch } from '../../../data/model';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Md5 } from 'ts-md5/dist/md5';
import { Paging, QueryFilter } from '../../../data/dt';
import languages from '../../../../assets/i18n/languages.json';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {
  @ViewChild('BranchModal') public BranchModal: ModalDirective;

  public paging: Paging;
  public q: QueryFilter;
  public listBranch = [];
  public listCompany = [];
  public listTypeTram = TypeTram;
  public ckeConfig: any;
  public Item: Branch;
  public progress: number;
  public domainImage = domainImage;
  public httpOptions: any;
  public role: number;
  companyId: number;
  TypeTram: number;
  isdisabled: boolean;
  public lstLanguage: Array<string> = languages;
  constructor(
    public http: HttpClient,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    public toastr: ToastrService,
    public translate: TranslateService
  ) {
    this.Item = new Branch();
    this.paging = new Paging();
    this.paging.page = 1;
    this.paging.page_size = 10;
    this.paging.query = "1=1";
    this.paging.order_by = "BranchId Desc";
    this.paging.item_count = 0;
    this.isdisabled = false;
    this.q = new QueryFilter();
    this.q.txtSearch = "";
    this.role = -1;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      })
    }
    translate.addLangs(this.lstLanguage);
  }

  ngOnInit() {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };


    var json = JSON.parse(localStorage.getItem('roles'));
    this.companyId = parseInt(localStorage.getItem('companyId'));
    if (json.length > 0) {
      for (var i = 0; i < json.length; i++) {
        if (json[i].RoleId == 1) {
          this.role = 1;
        }
      }
    }
    this.GetListBranch();
    if (this.role == 1) {
      this.GetListCompany();
    }

  }
  GetListBranch() {
    if (this.role != 1) {
      this.paging.query += ' and CompanyId = ' + this.companyId;
    }
    this.http.get('/api/branch/GetByPageAllTram?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listBranch = res["data"];
          this.paging.item_count = res["metadata"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  GetListCompany() {
    this.http.get('/api/company/GetByPage?page=1&query=1=1&order_by=', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listCompany = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  //Chuyển trang
  PageChanged(event) {
    this.paging.page = event.page;
    this.GetListBranch();
  }
  //Toast cảnh báo
  toastWarning(msg): void {
    this.toastr.warning(msg, this.translate.instant('TOAST.WARNING'));
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, this.translate.instant('TOAST.SUCCESS'));
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, this.translate.instant('TOAST.ERROR'));
  }
  //Search
  QueryChanged() {
    let query = '';
    if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
      if (query != '') {
        query += ' and Name.Contains("' + this.q.txtSearch + '")';
      }
      else {
        query += 'Name.Contains("' + this.q.txtSearch + '")';
      }
    }
    if (this.q.CompanyId != undefined && this.q.CompanyId != -1) {
      if (query != '') {
        query += ' and CompanyId = ' + this.q.CompanyId;
      }
      else {
        query += 'CompanyId = ' + this.q.CompanyId;
      }
    }
    if (this.role != 1) {
      if (query != '') {
        query += ' and CompanyId = ' + this.companyId;
      }
      else {
        query += 'CompanyId = ' + this.companyId;
      }
    }
    if (TypeTram != undefined) {
      if (query != '') {
        if (this.q.TypeTram != undefined) {
          query += 'and TypeTram = ' + this.q.TypeTram;
        }
      }
      else {
        if (this.q.TypeTram != undefined) {
           query += 'TypeTram = ' + this.q.TypeTram;
        }       
      }
    }
    if (query == '')
      this.paging.query = '1=1';
    else
      this.paging.query = query;

    this.GetListBranch();
  }

  //Mở modal thêm mới
  OpenBranchModal(item) {
    this.Item = new Branch();
    this.progress = undefined;
    if (item != undefined) {
      this.isdisabled = true;
      this.Item = JSON.parse(JSON.stringify(item));
    }
    else {
      this.isdisabled = false;
    }
    this.BranchModal.show();
  }

  //Thêm mới khách hàng
  SaveBranch() {
    if (this.Item.Code == undefined || this.Item.Code == '') {
      this.toastWarning(this.translate.instant('TOAST.BRANCH.TOAST_1'));
      return;
    } else if (this.Item.Code.replace(/ /g, '') == '') {
      this.toastWarning(this.translate.instant('TOAST.BRANCH.TOAST_1'));
      return;
    } else if (this.Item.Name == undefined || this.Item.Name == '') {
      this.toastWarning(this.translate.instant('TOAST.BRANCH.TOAST_2'));
      return;
    } else if (this.Item.Name.replace(/ /g, '') == '') {
      this.toastWarning(this.translate.instant('TOAST.BRANCH.TOAST_2'));
      return;
    } else if (this.Item.Email == undefined || this.Item.Email == '') {
      this.toastWarning(this.translate.instant('TOAST.BRANCH.TOAST_3'));
      return;
    } else if (this.Item.Email.replace(/ /g, '') == '') {
      this.toastWarning(this.translate.instant('TOAST.BRANCH.TOAST_3'));
      return;
    } else if (this.Item.Phone == undefined || this.Item.Phone == '') {
      this.toastWarning(this.translate.instant('TOAST.BRANCH.TOAST_4'));
      return;
    }

    this.Item.UserId = parseInt(localStorage.getItem("userId"));

    if (this.Item.BranchId == undefined) {
      if (this.role != 1) {
        this.Item.CompanyId = this.companyId;
      }
      let obj = JSON.parse(JSON.stringify(this.Item));

      this.http.post('/api/Branch', obj, this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.GetListBranch();
            this.BranchModal.hide();
            this.toastSuccess(this.translate.instant('TOAST.BRANCH.TOAST_5'));
          }
          else if (res["meta"]["error_code"] == 212) {
            this.toastWarning(this.translate.instant('TOAST.BRANCH.TOAST_6'));
          }
          else {
            this.toastError(res["meta"]["error_message"]);
          }
        },
        (err) => {
          this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
        }
      );
    }
    else {
      this.http.put('/api/Branch/' + this.Item.BranchId, this.Item, this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.GetListBranch();
            this.BranchModal.hide();
            this.toastSuccess(res["meta"]["error_message"]);
          }
          else if (res["meta"]["error_code"] == 212) {
            this.toastWarning(this.translate.instant('TOAST.BRANCH.TOAST_6'));
          }
          else {
            this.toastError(res["meta"]["error_message"]);
          }
        },
        (err) => {
          this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
        }
      );
    }


  }

  //Popup xác nhận xóa
  ShowConfirmDelete(Id) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: this.translate.instant('TOAST.CONFIM'),
      childComponent: SimpleModalComponent,
      data: {
        text: this.translate.instant('TOAST.CONFIM_DELETE')
      },
      actionButtons: [
        {
          text: this.translate.instant('TOAST.YES'),
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.DeleteBranch(Id);
          }
        },
        {
          text: this.translate.instant('TOAST.CLOSE'),
          buttonClass: 'btn btn-default',

        }
      ],
    });
  }

  DeleteBranch(Id) {
    this.http.delete('/api/Branch/' + Id, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.GetListBranch();
          this.viewRef.clear();
          this.toastSuccess(this.translate.instant('TOAST.NOITIFY_DELETE'));
        }
        else {
          this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
        }
      },
      (err) => {
        this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
      }
    );
  }

  upload(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);
    console.log(formData);
    const uploadReq = new HttpRequest('POST', 'api/upload/uploadImage/6', formData, {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      }),
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response) {
        this.Item.Avatar = event.body["data"].toString();
      }
    });
  }
  //
  RemoveImage() {
    this.Item.Avatar = undefined;
    this.progress = undefined;
  }

  SortTable(str) {
    let First = "";
    let Last = "";
    if (this.paging.order_by != "") {
      First = this.paging.order_by.split(" ")[0];
      Last = this.paging.order_by.split(" ")[1];
    }

    if (First != str) {
      this.paging.order_by = str + " Desc";
    }
    else {
      Last = Last == "Asc" ? "Desc" : "Asc";
      this.paging.order_by = str + " " + Last;
    }

    this.GetListBranch();
  }

  GetClassSortTable(str) {
    if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
      return "sorting";
    }
    else {
      if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
      else return "sorting_asc";
    }
  }
 
}
