import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../../service/common.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { Function } from '../../../data/model';
import languages from '../../../../assets/i18n/languages.json';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-function',
  templateUrl: './function.component.html',
  styleUrls: ['./function.component.scss']
})
export class FunctionComponent implements OnInit {
  @ViewChild('modalFunction') public modalFunction: ModalDirective;

  public paging: Paging;
  public q: QueryFilter;

  public listFunction = [];
  public listFunctionParent = [];

  public Item: Function;

  public httpOptions: any;
  public lstLanguage: Array<string> = languages;
  constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public translate: TranslateService) {
    this.Item = new Function();

    this.paging = new Paging();
    this.paging.page = 1;
    this.paging.page_size = 10;
    this.paging.query = "1=1";
    this.paging.order_by = "FunctionId Desc";
    this.paging.item_count = 0;

    this.q = new QueryFilter();
    this.q.txtSearch = "";

    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      })
    }
    translate.addLangs(this.lstLanguage);
  }

  ngOnInit() {
    this.GetListFunction();
  }

  //Get danh sách chức năng
  GetListFunction() {
    this.http.get('/api/function/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listFunction = res["data"];
          this.paging.item_count = res["metadata"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  GetListFunctionParent(Id) {
    this.http.get('/api/function/listFunction', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listFunctionParent = res["data"];
          this.listFunctionParent.forEach(item => {
            if (item.FunctionId == Id) {
              item.disabled = true;
            }

            item.Space = "";
            for (var i = 0; i < (item.Level) * 7; i++) {
              item.Space += "&nbsp;";
            }
          })
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  //Chuyển trang
  PageChanged(event) {
    this.paging.page = event.page;
    this.GetListFunction();
  }
  //Toast cảnh báo
  toastWarning(msg): void {
    this.toastr.warning(msg, this.translate.instant('TOAST.WARNING'));
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, this.translate.instant('TOAST.SUCCESS'));
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, this.translate.instant('TOAST.ERROR'));
  }
  //
  QueryChanged() {
    let query = '';
    if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
      if (query != '') {
        query += ' and (Name.Contains("' + this.q.txtSearch + '") OR Code.Contains("' + this.q.txtSearch + '"))';
      }
      else {
        query += '(Name.Contains("' + this.q.txtSearch + '") OR Code.Contains("' + this.q.txtSearch + '"))';
      }
    }

    if (query == '')
      this.paging.query = '1=1';
    else
      this.paging.query = query;

    this.GetListFunction();
  }


  OpenModalFunction(item) {
    this.Item = new Function();
    if (item == undefined) {
      this.GetListFunctionParent(undefined);
    }
    else {
      this.Item = Object.assign(this.Item, item);
      if (this.Item.FunctionParentId == 0) this.Item.FunctionParentId = undefined;
      this.GetListFunctionParent(item.FunctionId);
    }

    this.modalFunction.show();
  }

  SaveFunc() {
    if (this.Item.Code == undefined || this.Item.Code == '') {
      this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_1'));
      return;
    } else if (this.Item.Code.replace(/ /g, '') == '') {
      this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_1'));
      return;
    } else if (this.Item.Name == undefined || this.Item.Name == '') {
      this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_2'));
      return;
    } else if (this.Item.Name.replace(/ /g, '') == '') {
      this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_2'));
      return;
    } else if (this.Item.Url == undefined || this.Item.Url == '') {
      this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_3'));
      return;
    } else if (this.Item.Url.replace(/ /g, '') == '') {
      this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_3'));
      return;
    } else if (this.Item.Icon == undefined || this.Item.Icon == '') {
      this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_4'));
      return;
    } else if (this.Item.Icon.replace(/ /g, '') == '') {
      this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_4'));
      return;
    }

    let obj = JSON.parse(JSON.stringify(this.Item));
    if (this.Item.FunctionId == undefined) {
      this.http.post('/api/function', this.Item, this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.GetListFunction();
            this.modalFunction.hide();
            this.toastSuccess(this.translate.instant('TOAST.NOITIFY_SUCCESS'));
          }
          else if (res["meta"]["error_code"] == 211) {
            this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_5'));
          }
          else if (res["meta"]["error_code"] == 215) {
            this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_6'));
          }
          else {
            this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
          }
        },
        (err) => {
          this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
        }
      );
    }
    else {
      if (obj.FunctionParentId == null) obj.FunctionParentId = 0;

      this.http.put('/api/function/' + obj.FunctionId, obj, this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.GetListFunction();
            this.modalFunction.hide();
            this.toastSuccess(this.translate.instant('TOAST.UPDATE_SUCCESS'));
          }
          else if (res["meta"]["error_code"] == 211) {
            this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_5'));
          }
          else if (res["meta"]["error_code"] == 215) {
            this.toastWarning(this.translate.instant('TOAST.FUNCTION.TOAST_6'));
          }
          else {
            this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
          }
        },
        (err) => {
          this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
        }
      );
    }
  }


  //Popup xác nhận xóa
  ShowConfirmDelete(Id) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: this.translate.instant('TOAST.CONFIM'),
      childComponent: SimpleModalComponent,
      data: {
        text: this.translate.instant('TOAST.CONFIM_DELETE')
      },
      actionButtons: [
        {
          text: this.translate.instant('TOAST.YES'),
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.Delete(Id);
          }
        },
        {
          text: this.translate.instant('TOAST.CLOSE'),
          buttonClass: 'btn btn-default',

        }
      ],
    });
  }

  Delete(Id) {
    this.http.delete('/api/function/' + Id, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.GetListFunction();
          this.viewRef.clear();
          this.toastSuccess(this.translate.instant('TOAST.NOITIFY_DELETE'));
        }
        else {
          this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
        }
      },
      (err) => {
        this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
      }
    );
  }

  SortTable(str) {
    let First = "";
    let Last = "";
    if (this.paging.order_by != "") {
      First = this.paging.order_by.split(" ")[0];
      Last = this.paging.order_by.split(" ")[1];
    }

    if (First != str) {
      this.paging.order_by = str + " Desc";
    }
    else {
      Last = Last == "Asc" ? "Desc" : "Asc";
      this.paging.order_by = str + " " + Last;
    }

    this.GetListFunction();
  }

  GetClassSortTable(str) {
    if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
      return "sorting";
    }
    else {
      if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
      else return "sorting_asc";
    }
  }

}
