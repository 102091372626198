
 //export const domainImage = 'http://localhost:5001/uploads/thumbs/';
export const domainImage = 'http://dangnhap.net/uploads/thumbs/';



export const navItems = [
	{
		name: 'Trang tổng quan',
		url: '/dashboard',
		icon: 'icon-home'
	}
];

export const typeCategoryNews = [
	{
		Id: 1,
		Name: 'Tin văn bản'
	},
	// {
	// 	Id: 2,
	// 	Name: 'Tin thông báo'
	// },
	{
		Id: 3,
		Name: 'Tin hình ảnh'
	},
	{
		Id: 4,
		Name: 'Tin video'
	},
	// {
	// 	Id: 5,
	// 	Name: 'Tin đính kèm'
	// }
]
export const TypeTram = [
  {
    Id: 1,
    Name: 'Trạm Trộn'
  },
   {
   	Id: 2,
   	Name: 'Trạm Cân'
  },
]
export const typeCategoryPage = [
	{
		Id: 6,
		Name: 'Trang bình thường',
		Group: 'Trang bình thường'
	},
	{
		Id: 7,
		Name: 'Dofollow',
		Group: 'Liên kết trên trang'
	},
	{
		Id: 8,
		Name: 'Dofollow',
		Group: 'Liên kết ngoài trang'
	},
	{
		Id: 9,
		Name: 'Nofollow',
		Group: 'Liên kết trên trang'
	},
	{
		Id: 10,
		Name: 'Nofollow',
		Group: 'Liên kết ngoài trang'
	}
];

export const Language = [
	{
		Id: 1,
		Name: 'Tiếng Việt'
	},
	{
		Id: 7,
		Name: 'Tiếng Anh'
	}
];

export const Paging = {
	page: 1,
	page_size: 10,
	query: '1=1',
	order_by: '',
	item_count: 0
};

export const Filter = {
	txtSearch: ''
}

export const typeSlide = [
	{
		Id: 1,
		Name: 'loại 1'
	},
	{
		Id: 2,
		Name: 'loại 2'
	},
	{
		Id: 3,
		Name: 'loại 3'
	},
	{
		Id: 4,
		Name: 'loại 4'
	}
]

export const typeRank = [
	{
		Id: 1,
		Name: 'Diện tích'
	},
	{
		Id: 2,
		Name: 'Đơn giá'
	}
]

export const TypeUpload = [
	{
		Id: 1,
		Name: 'Tin tức'
	},
	{
		Id: 2,
		Name: 'Sản phẩm'
	},
	{
		Id: 3,
		Name: 'Slide'
	},
	{
		Id: 4,
		Name: 'Icon'
	},
	{
		Id: 5,
		Name: 'Danh mục'
	},
	{
		Id: 6,
		Name: 'Loại khác'
	}
]

export const OrderStatus = [
	{
		Id: 1,
		Name: 'Khởi tạo',
		Class: 'badge badge-primary'
	},
	{
		Id: 2,
		Name: 'Đã xác nhận',
		Class: 'badge badge-info'
	},
	{
		Id: 3,
		Name: 'Đang giao hàng',
		Class: 'badge badge-light'
	},
	{
		Id: 4,
		Name: 'Đã giao hàng',
		Class: 'badge badge-warning'
	},
	{
		Id: 5,
		Name: 'Bị hủy',
		Class: 'badge badge-danger'
	}
]

export const TypeContact = [
	{
		Id: 1,
		Name: 'Liên hệ'
	},
	{
		Id: 2,
		Name: 'Đăng ký nhận tin'
	},
	{
		Id: 3,
		Name: 'Đặt lịch và báo giá'
	}
]


export const ProductReviewStatus = [
	{
		Id: 1,
		Name: 'Khởi tạo'
	},
	{
		Id: 2,
		Name: 'Đã được duyệt'
	},
	{
		Id: 3,
		Name: 'Không được duyệt'
	}
]

export const PaymentOrderStatus = [
	{
		Id: 1,
		Name: 'Chưa thanh toán',
		Class: 'badge badge-primary'
	},
	{
		Id: 2,
		Name: 'Đã thanh toán hết',
		Class: 'badge badge-info'
	},
	{
		Id: 3,
		Name: 'Chưa thanh toán hết',
		Class: 'badge badge-light'
	},
	{
		Id: 4,
		Name: 'Không thanh toán',
		Class: 'badge badge-warning'
	},
	{
		Id: 5,
		Name: 'Thanh toán lỗi',
		Class: 'badge badge-danger'
	}
]

export const ListLangs = [
  {
    Id: 'vi',
    Name: 'Vietnamese',
    Icon: '../../assets/img/avatars/1.jpg'
  },
  {
    Id: 'en',
    Name: 'English',
    Icon: '../../assets/img/avatars/1.jpg'
  },
  {
    Id: 'cn',
    Name: 'Chinese',
    Icon: '../../assets/img/avatars/1.jpg'
  }
]
