import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { Branch, Order, OrderItem, OrderTram } from '../../data/model';
import { domainImage, OrderStatus, TypeTram } from '../../data/const';
import { forEach } from '@angular/router/src/utils/collection';
import { publish } from 'rxjs/operators';
import { Paging, QueryFilter } from '../../data/dt';



@Component({
  selector: 'app-order',
  templateUrl: './ordertram.component.html',
  styleUrls: ['./ordertram.component.scss']
})
export class OrderTramComponent implements OnInit {
  @ViewChild('BranchModal') public BranchModal: ModalDirective;

  public paging: Paging;
  public q: QueryFilter;
  public listBranch = [];
  public listCompany = [];
  public listCuaVL = [];
  public listTypeTram = TypeTram;
  public ckeConfig: any;
  public Item: OrderTram;
  public progress: number;
  public domainImage = domainImage;
  public httpOptions: any;
  public role: number;
  companyId: number;
  TypeTram: number;
  TypePhieu: number;
  isdisabled: boolean;

  constructor(
    public http: HttpClient,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    public toastr: ToastrService
  ) {
    this.Item = new OrderTram();
    this.paging = new Paging();
    this.paging.page = 1;
    this.paging.page_size = 10;
    this.paging.query = "1=1";
    this.paging.order_by = "OrderTramId Desc";
    this.paging.item_count = 0;
    this.isdisabled = false;
    this.q = new QueryFilter();
    this.q.txtSearch = "";
    this.role = -1;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      })
    }
  }

  ngOnInit() {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };


    var json = JSON.parse(localStorage.getItem('roles'));
    this.companyId = parseInt(localStorage.getItem('companyId'));
    if (json.length > 0) {
      for (var i = 0; i < json.length; i++) {
        if (json[i].RoleId == 1) {
          this.role = 1;
        }
      }
    }
    this.GetListBranch();
    if (this.role == 1) {
      this.GetListCompany();
    }
  }
  GetListBranch() {
    if (this.role != 1) {
      this.paging.query += ' and CompanyId = ' + this.companyId;
    }
    this.http.get('/api/OrderTram/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listBranch = res["data"];
          this.paging.item_count = res["metadata"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  //GetCuaVL() {

  //  this.QueryChangedCuaVL();

  //  this.http.get('/api/ImportManagement/GetCuaVL/?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&KDL=', this.httpOptions).subscribe(
  //    (res) => {
  //      if (res["meta"]["error_code"] == 200) {
  //        this.listCuaVL = res["data"];
  //      }
  //    },
  //    (err) => {
  //      console.log("Error: connect to API");
  //    }
  //  );
  //}

  GetListCompany() {
    this.http.get('/api/company/GetByPage?page=1&query=1=1&order_by=', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listCompany = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  //Chuyển trang
  PageChanged(event) {
    this.paging.page = event.page;
    this.GetListBranch();
  }
  //Toast cảnh báo
  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }
  //QueryChangedCuaVL() {

  //  let query = '';
  //  if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
  //    if (query != '') {
  //      query += " and 1=1";
  //    }
  //    else {
  //      query += " 1=1 ";
  //    }
  //  }

  //  if (this.q.fromdate != undefined && this.q.todate != undefined && this.q.fromdate != '' && this.q.todate != '') {
  //    if (query != '') {
  //      query += " AND ( NGAY >= convert(datetime ,'" + this.q.fromdate + " 00:00:00.000') AND NGAY <= convert(datetime ,'" + this.q.todate + " 23:59:59.999') )";
  //    }
  //    else {
  //      query += " AND ( NGAY >= convert(datetime ,'" + this.q.fromdate + " 00:00:00.000') AND NGAY <= convert(datetime ,'" + this.q.todate + " 23:59:59.999') )";
  //    }
  //  }
  //  this.getQueryPar();
  //  //if (this.q.TENNV != undefined && this.q.TENNV != '') {
  //  //  if (query != '') {
  //  //    query += " AND nv.TENNV = N'" + this.q.TENNV + "'";
  //  //  }
  //  //  else {
  //  //    query += "nv.TENNV = N'" + this.q.TENNV + "'";
  //  //  }
  //  //}

  //  if (this.role == 3) {
  //    this.paging.CompanyId = this.companyId;
  //  }
  //  else {
  //    if (this.q.CompanyId != undefined) {
  //      this.paging.CompanyId = this.q.CompanyId;
  //    }
  //    else {
  //      this.paging.CompanyId = 0;
  //    }
  //  }

  //  if (this.q.Branchlist != undefined) {
  //    this.paging.Branchid = this.q.Branchlist;
  //    //this.paging.Branchid = '';
  //    //this.q.Branchlist.forEach((item, index) => {
  //    //  if (item != '') {
  //    //    this.paging.Branchid = this.paging.Branchid + item + ',';
  //    //  }
  //    //});
  //  }
  //  else {
  //    this.paging.Branchid = '';
  //    if (this.role != 1) {
  //      this.listBranchSearch.forEach((item, index) => {
  //        if (item != '') {
  //          this.paging.Branchid = this.paging.Branchid + item["BranchId"] + ',';
  //        }
  //      });
  //    }
  //  }
  //  if (query == '')
  //    this.paging.query = '1=1';
  //  else
  //    this.paging.query = query;
  //  if (this.q.Branchlist == undefined) {
  //    this.toastWarning("Bạn chưa chọn trạm trộn!");
  //  } else {
  //    //    this.GetListOrder();
  //  }

  //  // this.GetListOrderBarch();
  //}
  //Search
  QueryChanged() {
    let query = '';
    if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
      if (query != '') {
        query += ' and Name.Contains("' + this.q.txtSearch + '")';
      }
      else {
        query += 'Name.Contains("' + this.q.txtSearch + '")';
      }
    }
    if (this.q.CompanyId != undefined && this.q.CompanyId != -1) {
      if (query != '') {
        query += ' and CompanyId = ' + this.q.CompanyId;
      }
      else {
        query += 'CompanyId = ' + this.q.CompanyId;
      }
    }
    if (this.role != 1) {
      if (query != '') {
        query += ' and CompanyId = ' + this.companyId;
      }
      else {
        query += 'CompanyId = ' + this.companyId;
      }
    }
    if (TypeTram != undefined) {
      if (query != '') {
        if (this.q.TypeTram != undefined) {
          query += 'and TypeTram = ' + this.q.TypeTram;
        }
      }
      else {
        if (this.q.TypeTram != undefined) {
          query += 'TypeTram = ' + this.q.TypeTram;
        }
      }
    }
    if (query == '')
      this.paging.query = '1=1';
    else
      this.paging.query = query;

    this.GetListBranch();
  }

  //Mở modal thêm mới
  OpenBranchModal(item) {
    this.Item = new OrderTram();
    this.progress = undefined;
    if (item != undefined) {
      this.isdisabled = true;
      this.Item = JSON.parse(JSON.stringify(item));
    }
    else {
      this.isdisabled = false;
    }
    this.BranchModal.show();
  }

  //Thêm mới khách hàng
  SaveBranch() {
    if (this.Item.Code == undefined || this.Item.Code == '') {
      this.toastWarning("Chưa nhập mã!");
      return;
    } else if (this.Item.Code.replace(/ /g, '') == '') {
      this.toastWarning("Chưa nhập mã!");
      return;
    } else if (this.Item.Name == undefined || this.Item.Name == '') {
      this.toastWarning("Chưa nhập tên!");
      return;
    } else if (this.Item.Name.replace(/ /g, '') == '') {
      this.toastWarning("Chưa nhập tên!");
      return;
    }

    /*this.Item.UserId = parseInt(localStorage.getItem("userId"));*/

    if (this.Item.OrderTramId == undefined) {
      let obj = JSON.parse(JSON.stringify(this.Item));

      this.http.post('/api/OrderTram', obj, this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.GetListBranch();
            this.BranchModal.hide();
            this.toastSuccess("Thêm mới thành công!");
          }
          else if (res["meta"]["error_code"] == 212) {
            this.toastWarning("Mã chi nhánh đã tồn tại!");
          }
          else {
            this.toastError(res["meta"]["error_message"]);
          }
        },
        (err) => {
          this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
        }
      );
    }
    else {
      this.http.put('/api/OrderTram/' + this.Item.OrderTramId, this.Item, this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.GetListBranch();
            this.BranchModal.hide();
            this.toastSuccess(res["meta"]["error_message"]);
          }
          else if (res["meta"]["error_code"] == 212) {
            this.toastWarning("Mã chi nhánh đã tồn tại!");
          }
          else {
            this.toastError(res["meta"]["error_message"]);
          }
        },
        (err) => {
          this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
        }
      );
    }


  }

  //Popup xác nhận xóa
  ShowConfirmDelete(Id) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: 'Xác nhận',
      childComponent: SimpleModalComponent,
      data: {
        text: "Bạn có chắc chắn muốn xóa bản ghi này?"
      },
      actionButtons: [
        {
          text: 'Đồng ý',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.DeleteBranch(Id);
          }
        },
        {
          text: 'Đóng',
          buttonClass: 'btn btn-default',

        }
      ],
    });
  }

  DeleteBranch(Id) {
    this.http.delete('/api/OrderTram/' + Id, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.GetListBranch();
          this.viewRef.clear();
          this.toastSuccess("Xóa thành công!");
        }
        else {
          this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
        }
      },
      (err) => {
        this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
      }
    );
  }

  SortTable(str) {
    let First = "";
    let Last = "";
    if (this.paging.order_by != "") {
      First = this.paging.order_by.split(" ")[0];
      Last = this.paging.order_by.split(" ")[1];
    }

    if (First != str) {
      this.paging.order_by = str + " Desc";
    }
    else {
      Last = Last == "Asc" ? "Desc" : "Asc";
      this.paging.order_by = str + " " + Last;
    }

    this.GetListBranch();
  }

  GetClassSortTable(str) {
    if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
      return "sorting";
    }
    else {
      if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
      else return "sorting_asc";
    }
  }
}
