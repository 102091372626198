import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { AuthService } from "./service/auth.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  // tslint:disable-next-line
  selector: "body",
  template:
    "<router-outlet></router-outlet><ngx-loading-bar></ngx-loading-bar>",
  //selector: 'app-root',
  //templateUrl: './app.component.html',
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private cookie: CookieService,
    private auth: AuthService
  ) {
    this.titleService.setTitle("");
    //this.myFuntion();
  }

  ngOnInit() {
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   window.scrollTo(0, 0);
    // });
    console.log("Ex", this.cookie.get("Expire"));
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => this.titleService.setTitle(event["title"]));
  }

  ngAfterViewInit() {
    this.deToken();
  }

  myFuntion() {
    setInterval(() => {
      if (this.cookie.get("Expire") == "") {
        this.auth.logout();
      }
    }, 10000);
  }

  deToken() {
    var token = localStorage.getItem("access_token");
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    let payload = JSON.parse(jsonPayload);
    let exp = payload.exp;
    let currentTimestamp = Math.floor(Date.now() / 1000);
    if (currentTimestamp >  exp) {
      this.auth.logout();
    }
  }
}
