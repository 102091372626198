import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
// import { LanguageComponent } from './views/language/language.component';
// import { OrderComponent } from './views/order/order.component';
// import { ProductComponent } from './views/product/product.component'
// import { CustomerComponent } from './views/customer/customer.component';
// import { SlideComponent } from './views/slide/slide.component';
// import { MaterialComponent } from './views/material/material.component';

import { MenuComponent } from './views/category/menu/menu.component';
import { NewsComponent } from './views/category/news/news.component';
import { PageComponent } from './views/category/page/page.component';
import { CateProductComponent } from './views/category/product/product.component';
import { ManufacturerComponent } from './views/category/manufacturer/manufacturer.component';
import { RankComponent } from './views/category/rank/rank.component';
import { TrademarkComponent } from './views/category/trademark/trademark.component';

import { ConfigThumbComponent } from './views/config/config-thumb/config-thumb.component';
import { ConfigGeneralComponent } from './views/config/config-general/config-general.component';
import { ConfigTableComponent } from './views/config/config-table/config-table.component';

import { NewsTextComponent } from './views/content/news-text/news-text.component';
import { BlockComponent } from './views/content/block/block.component';
import { CommentComponent } from './views/content/comment/comment.component';

import { CustomerComponent } from './views/customer/customer.component';

import { DashboardComponent } from './views/dashboard/dashboard.component';

import { CompanyComponent } from './views/general/company/company.component';
import { WebsiteComponent } from './views/general/website/website.component';
import { BankComponent } from './views/general/bank/bank.component';
import { DepartmentComponent } from './views/general/department/department.component';
import { PositionComponent } from './views/general/position/position.component';
import { TypeAttributeComponent } from './views/general/type-attribute/type-attribute.component';

import { LanguageComponent } from './views/language/language.component';

import { MaterialComponent } from './views/material/material.component';

import { OrderComponent } from './views/order/order.component';
import { OrderTramComponent } from './views/ordertram/ordertram.component';

import { ProductComponent } from './views/product/product.component';

import { SlideComponent } from './views/slide/slide.component';
import { TramcanComponent } from './views/tramcan/tramcan.component';
import { FunctionComponent } from './views/system/function/function.component';
import { RoleComponent } from './views/system/role/role.component';

import { UserComponent } from './views/user/user.component';

import { PartnerComponent } from './views/category/partner/partner.component';

import { BranchComponent } from './views/general/branch/branch.component';

import { ServiceComponent } from './views/service/service.component';

import { ContactComponent } from './views/general/contact/contact.component';

import { AttribuiteComponent } from './views/general/attribuite/attribuite.component';

import { ReviewProductComponent } from './views/review-product/review-product.component';

import { AuthGuard } from './auth.guard';
import { ImportManagement } from './views/importmanagement/importmanagement.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Đăng nhập'
    },
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: ''
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Trang tổng quan'
        }
      },
      {
        path: 'company',
        component: CompanyComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quản lý công ty'
        }
      },
      {
        path: 'system/function',
        component: FunctionComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quản lý chức năng'
        }
      },
      {
        path: 'system/role',
        component: RoleComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quản lý phân quyền'
        }
      },
      {
        path: 'system/user',
        component: UserComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Người dùng'
        }
      },
      {
        path: 'branch',
        component: BranchComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quản lý trạm trộn'
        }
      },
      {
        path: 'report/rpdonhang',
        component: OrderComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Báo cáo đơn hàng'
        }
      },
      {
        path: 'report/slide',
        component: SlideComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Thống kê đơn hàng'
        }
      },
      {
        path: 'report/tramcan',
        component: TramcanComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Thống kê trạm cân'
        }
      },
      {
        path: 'report/importmanagement',
        component: ImportManagement,
        canActivate: [AuthGuard],
        data: {
          title: 'Quản lý kho'
        }
      },
      {
        path: 'bank',
        component: BankComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Phần mềm quản lý xe'
        }
      },
      {
        path: 'contact',
        component: ContactComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quản lý Camera'
        }
      },
      {
        path: 'customer',
        component: CustomerComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quản lý cấp phối'
        }
      },
      {
        path: 'ordertram',
        component: OrderTramComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Quản lý trạm vật liệu'
        }
      },
    ]
  },
  {
    path: '**',
    component: P404Component,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
