import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef, ViewEncapsulation } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { domainImage } from '../../data/const';
import { Slide } from '../../data/model';
import { Paging, QueryFilter, tablesilde } from '../../data/dt';
import { forEach } from '@angular/router/src/utils/collection';
import { debug } from 'util';
import { CommonService } from '../../service/common.service';
import { DatePipe } from '@angular/common';
import languages from '../../../assets/i18n/languages.json';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-slide',
  templateUrl: './tramcan.component.html',
  styleUrls: ['./tramcan.component.scss'],
  styles: ['.th-custom { position: sticky !important; top: -1px !important; z-index: 99 !important; color: white !important; background: #3c8dbc !important; }'],
  encapsulation: ViewEncapsulation.None
})
export class TramcanComponent implements OnInit {
  @ViewChild('SlideModal') public SlideModal: ModalDirective;
  @ViewChild('file') file: ElementRef;

  public paging: any;
  public q: any;
  public listSlide = [];
  public listSlideTong = [];
  public listTenMacBeTong = [];
  public listKH = [];
  public listBienSo = [];
  public listCompany = [];
  public listBranchSearch = [];
  public ListVatLieu = [];
  public ListNV = [];
  public ckeConfig: any;
  public tablesilde = [];
  public listXeVaoRa = [];
  public listXeVaoRaBienXeBox = []; 
  public listXeVaoRaTENVATLIEUBox = [];
  public listXeVaoRaUSERNAMEBox = [];
  public listXeVaoRaDONVITINHBox = [];
  public listXeVaoRaLOAICANBox = [];

  public role: number;
  companyId: number;
  BranchId: string;
  BIENXE: string;
  TENVATLIEU: string;
  USERNAME: string;
  DONVI: string;
  LOAICAN: string;
  tongKg: string;
  tongm3: string;
  querytable: string;
  UserId: number;
  public companyselect: number;
  public companyselectsearch: number;


  public progress: number;
  public message: string;
  public domainImage = domainImage;
  public httpOptions: any;
  public lstLanguage: Array<string> = languages;
  constructor(
    public http: HttpClient,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    public toastr: ToastrService,
    public datepipe: DatePipe,
    public commonService: CommonService,
    public translate: TranslateService
  ) {
    this.paging = {
      page: 1,
      page_size: 10,
      query: '(1=1)',
      order_by: 'NGAYDATHANG Desc',
      item_count: 0
    };

    this.q = {
      txtSearch: ''
    }

    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      })
    }
    translate.addLangs(this.lstLanguage);
  }

  ngOnInit() {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };
    let todaynew = new Date();
    //this.q.tungay = this.datepipe.transform(today, 'yyyy-MM-dd');
    this.q.tungay = this.datepipe.transform(todaynew, 'yyyy-MM-dd');
    this.q.denngay = this.datepipe.transform(todaynew, 'yyyy-MM-dd');
    this.paging.query = ' (1=1) ';
    if (this.q.tungay != undefined && this.q.tungay != undefined && this.q.denngay != '' && this.q.denngay != '') {
      this.paging.query += "AND ( [Lastupdated] >= convert(datetime ,'" + this.q.tungay + " 00:00:00.000') AND [Lastupdated] <= convert(datetime ,'" + this.q.denngay + " 23:59:59.999') )";
    }


    //
    var json = JSON.parse(localStorage.getItem('roles'));
    this.companyId = parseInt(localStorage.getItem('companyId'));
    this.UserId = parseInt(localStorage.getItem('userId'));
    this.BranchId = localStorage.getItem('BranchId');
    if (json.length > 0) {
      for (var i = 0; i < json.length; i++) {
        this.role = json[i].RoleId
      }
    }

    this.q.status = "1";
    this.q.ckbKhachHang = true;
    this.q.ckbXeTron = true;
    this.q.ckbMacBeTong = true;
    if (this.role == 3) {
      this.paging.CompanyId = this.companyId;
    }
    else {
      this.paging.CompanyId = 0;
    }

    this.paging.Branchid = '';
    if (this.role == 3) {
      this.GetListBranchSearchStart();
    }
    if (this.role == 4) {
      this.GetListBranchCTCon();
      this.paging.Branchid = this.BranchId;
    }
    if (this.role == 1) {
      this.GetListCompany();
    }
    if (this.role != 1) {
      this.GetListBranchSearchStart();
      this.paging.Branchid = this.BranchId;
      this.paging.CompanyId = this.companyId;
    }
    //if (localStorage.getItem('getlinkd') == "1") {
    //  if (localStorage.getItem('thoigiand') == '1') {
    //    let todaynew = new Date();
    //    //this.q.tungay = this.datepipe.transform(today, 'yyyy-MM-dd');
    //    this.q.tungay = this.datepipe.transform(todaynew, 'yyyy-MM-dd');
    //    this.q.denngay = this.datepipe.transform(todaynew, 'yyyy-MM-dd');
    //    this.paging.query = "( [t2].GIOBATDAU >= convert(datetime ,'" + this.q.tungay + " 00:00:00.000') AND [t2].GIOXONG <= convert(datetime ,'" + this.q.denngay + " 23:59:59.999') )";
    //  }
    //  if (localStorage.getItem('thoigiand') == '2') {
    //    let today = new Date();
    //    this.q.tungay = new Date(today.setDate(today.getDate() - today.getDay() + 1)).toISOString().substr(0, 10);
    //    this.q.denngay = new Date(today.setDate(today.getDate() - today.getDay() + 7)).toISOString().substr(0, 10);
    //    this.paging.query = "( [t2].GIOBATDAU >= convert(datetime ,'" + this.q.tungay + " 00:00:00.000') AND [t2].GIOXONG <= convert(datetime ,'" + this.q.denngay + " 23:59:59.999') )";
    //  }
    //  if (localStorage.getItem('thoigiand') == '3') {
    //    let today = new Date();
    //    this.q.tungay = new Date(today.setDate(today.getDate() - today.getDay() + 1 - 7)).toISOString().substr(0, 10);
    //    let today1 = new Date();
    //    this.q.denngay = new Date(today1.setDate(today1.getDate() - today1.getDay())).toISOString().substr(0, 10);
    //    this.paging.query = "( [t2].GIOBATDAU >= convert(datetime ,'" + this.q.tungay + " 00:00:00.000') AND [t2].GIOXONG <= convert(datetime ,'" + this.q.denngay + " 23:59:59.999') )";
    //  }
    //  if (localStorage.getItem('thoigiand') == '4') {
    //    var today2 = new Date();
    //    var lastDayOfMonth = new Date(today2.getFullYear(), today2.getMonth(), 2);
    //    this.q.tungay = lastDayOfMonth.toISOString().substr(0, 10);

    //    var today3 = new Date();
    //    var lastDayOfMonth1 = new Date(today3.getFullYear(), today3.getMonth() + 1, 1);
    //    this.q.denngay = lastDayOfMonth1.toISOString().substr(0, 10);
    //    this.paging.query = "( [t2].GIOBATDAU >= convert(datetime ,'" + this.q.tungay + " 00:00:00.000') AND [t2].GIOXONG <= convert(datetime ,'" + this.q.denngay + " 23:59:59.999') )";
    //  }
    //}
    //this.GetListSlide();
    this.GetListXeVaoRa();
  }
  TaoTable() {
    this.tablesilde = [];

    /*this.GetListVatLieu();*/
  }
  
  ExportExcel() {
    if (this.q.Branchlist == undefined || this.q.Branchlist == '') {
      this.toastr.warning(this.translate.instant('TOAST.TRAMCAN.TOAST_1'), this.translate.instant('TOAST.WARNING'));
      return;
    }
    let query = '';
    if (this.role == 3) {
      this.paging.CompanyId = this.companyId;
    }
    else {
      if (this.q.CompanyId != undefined) {
        this.paging.CompanyId = this.q.CompanyId;
      }
      else {
        this.paging.CompanyId = 0;
      }
    }
    if (this.q.Branchlist != undefined) {
      this.paging.Branchid = this.q.Branchlist;
    }
    if (this.q.tungay != undefined && this.q.tungay != undefined && this.q.denngay != '' && this.q.denngay != '') {
      if (query != '') {
        query += " AND ( [Lastupdated] >= convert(datetime ,'" + this.q.tungay + " 00:00:00.000') AND [Lastupdated] <= convert(datetime ,'" + this.q.denngay + " 23:59:59.999') )";
      }
      else {
        query += "( [Lastupdated] >= convert(datetime ,'" + this.q.tungay + " 00:00:00.000') AND [Lastupdated] <= convert(datetime ,'" + this.q.denngay + " 23:59:59.999') )";
      }
    }
    if (this.q.BIENXE != undefined) {
      if (query != '') {
        query += ' and BIENXE=' + this.q.BIENXE;
      }
      else {
        query += 'BIENXE=' + this.q.BIENXE;
      }
    }
    if (this.q.TENVATLIEU != undefined) {
      if (query != '') {
        query += ' and TENVATLIEU=' + this.q.TENVATLIEU;
      }
      else {
        query += 'TENVATLIEU=' + this.q.TENVATLIEU;
      }
    }
    if (this.q.USERNAME != undefined) {
      if (query != '') {
        query += ' and USERNAME=' + this.q.USERNAME;
      }
      else {
        query += 'USERNAME=' + this.q.USERNAME;
      }
    }
    if (this.q.USERNAME != undefined) {
      if (query != '') {
        query += ' and DONVI=' + this.q.DONVI;
      }
      else {
        query += 'DONVI=' + this.q.DONVI;
      }
    }
    if (this.q.LOAICAN != undefined) {
      if (query != '') {
        query += ' and LOAICAN=' + this.q.LOAICAN;
      }
      else {
        query += 'LOAICAN=' + this.q.LOAICAN;
      }
    }
    if (query == '')
      this.paging.query = ' (1=1) ';
    else
      this.paging.query = query;
    //this.GetListXeVaoRa();

    this.QueryChanged();


    let tenchinhanh = this.listBranchSearch.find(x => x.BranchId == this.paging.Branchid);
  //  debugger;
    fetch('/api/branch/GetReport?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&BIENSO=' + this.q.BIENXE + '&TENVATLIEU=' + this.q.TENVATLIEU + '&TENNV=' + this.q.USERNAME + '&DONVI=' + this.q.DONVI + '&LOAICAN=' + this.q.LOAICAN + '&XERACHUWA=' + this.q.XERACHUWA + '&tungaystr=' + this.q.tungay + '&denngaystr=' + this.q.denngay + '&tungay=' + this.q.tungay + '&denngay=' + this.q.denngay, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      }),
    })
      .then(function (response) {
        var blob = response.blob();
        return blob;
      }).then(blob => {
        var DateObj = new Date();
        var date = ('0' + DateObj.getDate()).slice(-2) + '_' + ('0' + (DateObj.getMonth() + 1)).slice(-2) + '_' + DateObj.getFullYear();
        this.DownloadFile(blob, this.commonService.ConvertUrl(tenchinhanh.Name) + "_thong_ke_tram_can_ngay_" + date + ".xlsx", 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
     //   this.DownloadFile(blob,   "_thong_ke_dn_hang" + date + ".xlsx", 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      });
  }
  DownloadFile(data: Blob, filename: string, mime: string): void {
    var blob = new Blob([data], { type: mime || 'application/octet-stream' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE workaround for "HTML7007: One or more blob URLs were 
      // revoked by closing the blob for which they were created. 
      // These URLs will no longer resolve as the data backing 
      // the URL has been freed."
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      var blobURL = window.URL.createObjectURL(blob);
      var tempLink = document.createElement('a');
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);
      tempLink.setAttribute('target', '_blank');
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    }
  }
  
  GetListCompany() {
    this.http.get('/api/company/GetByPage?page=1&query=1=1&order_by=', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listCompany = res["data"];
          if (localStorage.getItem('congtyd') != 'undefined') {
            var index = this.listCompany.find(x => x.CompanyId == parseInt(localStorage.getItem('companyId')));
            this.q.CompanyId = index.CompanyId;
            this.paging.CompanyId = parseInt(localStorage.getItem('congtyd'));
            this.companyId = parseInt(localStorage.getItem('companyId'));
            this.GetListBranchSearchStart();
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  BranchChange() {
    if (this.role == 3) {
      this.paging.CompanyId = this.companyId;
    }
    else {
      if (this.q.CompanyId != undefined) {
        this.paging.CompanyId = this.q.CompanyId;
      }
      else {
        this.paging.CompanyId = this.companyId;
      }
    }

    console.log(this.paging.CompanyId);
    
    if (this.q.Branchlist != undefined) {
      this.paging.Branchid = this.q.Branchlist;
    }
   // this.QueryChanged();
    this.http.get('/api/branch/GetXeVaoRaBienXeBox?page=1&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listXeVaoRaBienXeBox = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );

    this.http.get('/api/branch/GetXeVaoRaTENVATLIEUBox?page=1&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listXeVaoRaTENVATLIEUBox = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );

    this.http.get('/api/branch/GetXeVaoRaUSERNAMEBox?page=1&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listXeVaoRaUSERNAMEBox = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );

    this.http.get('/api/branch/GetXeVaoRaDONVIBox?page=1&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listXeVaoRaDONVITINHBox = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );

    this.http.get('/api/branch/GetXeVaoRaLOAICANBox?page=1&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listXeVaoRaLOAICANBox = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
    this.QueryChangedFind();
  }
  GetListXeVaoRa() {
    this.QueryChanged();
    this.http.get('/api/branch/GetXeVaoRa?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&BIENSO=' + this.q.BIENXE + '&TENVATLIEU=' + this.q.TENVATLIEU + '&TENNV=' + this.q.USERNAME + '&DONVI=' + this.q.DONVI + '&LOAICAN=' + this.q.LOAICAN + '&XERACHUWA=' + this.q.XERACHUWA + '&tungaystr=' + this.q.tungay + '&denngaystr=' + this.q.denngay, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listXeVaoRa = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );

   
  }
  GetListBranchSearchStart() {
    this.http.get('/api/branch/GetByPageTramCan?page=1&query=CompanyId = ' + this.companyId +' &order_by=', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listBranchSearch = res["data"];
          let localtramtron = localStorage.getItem('BranchId');
          if (localtramtron != 'undefined') {
            let ids = localtramtron.split(',').map(Number);
            this.listBranchSearch =  this.listBranchSearch.filter(x => ids.includes(x.BranchId));
          }
          if (localStorage.getItem('tramtrond') != 'undefined') {
            this.q.Branchlist = [];
            let lstnhombenh = localStorage.getItem('tramtrond').split(',');
            lstnhombenh.forEach(element => {
              this.q.Branchlist.push(parseInt(element));
            });
            /*this.GetListSlide();*/
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  GetListBranchCTCon() {
    this.http.get('/api/Order/GetByUser?id=' + this.UserId, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listBranchSearch = res["data"];
          if (localStorage.getItem('tramtrond') != 'undefined') {
            this.q.Branchlist = [];
            let lstnhombenh = localStorage.getItem('tramtrond').split(',');
            lstnhombenh.forEach(element => {
              this.q.Branchlist.push(parseInt(element));
            });
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  GetListBranchSearch() {
    this.http.get('/api/branch/GetByPageTramCan?page=1&query=CompanyId = ' + this.companyselectsearch + ' &order_by=', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listBranchSearch = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  GetBranch() {
    this.companyselectsearch = this.q.CompanyId;
    this.listBranchSearch = [];
    this.q.BranchId = null;
    this.GetListBranchSearch();
    this.paging.CompanyId = this.q.CompanyId;
  }
  //Chuyển trang
  PageChanged(event) {
    //this.paging.page = event.page;paging.page
    this.paging.page = event.page;
    this.QueryChangedFind();
    /*this.GetListSlide();*/
  }
  //phan trang
  PagesizeChanged() {
   // this.paging.page = event.page;
    this.paging.page = 1;
    this.QueryChangedFind();
    /*this.GetListSlide();*/
  }

  //Toast cảnh báo
  toastWarning(msg): void {
    this.toastr.warning(msg, this.translate.instant('TOAST.WARNING'));
  }

  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, this.translate.instant('TOAST.SUCCESS'));
  }

  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, this.translate.instant('TOAST.ERROR'));
  }
  //
  changeStatusckbKhachHang(event) {

    this.q.ckbKhachHang = event;
  }
  //
  QueryChanged() {
    //if (this.q.Branchlist == undefined || this.q.Branchlist == '') {
    //  this.toastr.warning('Chưa chọn trạm trộn !', 'Cảnh báo');
    //  return;
    //}   
    //if (this.role == 3) {
    //  this.paging.CompanyId = this.companyId;
    //}
    //else {
    //  if (this.q.CompanyId != undefined) {
    //    this.paging.CompanyId = this.q.CompanyId;
    //  }
    //  else {
    //    this.paging.CompanyId = 0;
    //  }
    //}
    
    //if (query == '')
    //  this.paging.query = '(1=1)';
    //else
    //  this.paging.query = query;

/*    this.GetListXeVaoRa();*/
    /*this.GetListSlide();*/

  }

  QueryChangedFind() {
    if (this.q.Branchlist == undefined || this.q.Branchlist == '') {
      this.toastr.warning(this.translate.instant('TOAST.TRAMCAN.TOAST_1'), this.translate.instant('TOAST.ERROR'));
      return;
    } 
    let query = '';
    if (this.role == 3) {
      this.paging.CompanyId = this.companyId;
    }
    else {
      if (this.q.CompanyId != undefined) {
        this.paging.CompanyId = this.q.CompanyId;
      }
      else {
        this.paging.CompanyId = 0;
      }
    }
    if (this.q.Branchlist != undefined) {
      this.paging.Branchid = this.q.Branchlist;
    }
    if (this.q.tungay != undefined && this.q.tungay != undefined && this.q.denngay != '' && this.q.denngay != '') {
      if (query != '') {
        query += " AND ( [Lastupdated] >= convert(datetime ,'" + this.q.tungay + " 00:00:00.000') AND [Lastupdated] <= convert(datetime ,'" + this.q.denngay + " 23:59:59.999') )";
      }
      else {
        query += "( [Lastupdated] >= convert(datetime ,'" + this.q.tungay + " 00:00:00.000') AND [Lastupdated] <= convert(datetime ,'" + this.q.denngay + " 23:59:59.999') )";
      }
    }
    if (this.q.BIENXE != undefined) {
      if (query != '') {
        query += ' and BIENXE=' + this.q.BIENXE;
      }
      else {
        query += 'BIENXE=' + this.q.BIENXE;
      }
    }
    if (this.q.TENVATLIEU != undefined) {
      if (query != '') {
        query += ' and TENVATLIEU=' + this.q.TENVATLIEU;
      }
      else {
        query += 'TENVATLIEU=' + this.q.TENVATLIEU;
      }
    }
    if (this.q.USERNAME != undefined) {
      if (query != '') {
        query += ' and USERNAME=' + this.q.USERNAME;
      }
      else {
        query += 'USERNAME=' + this.q.USERNAME;
      }
    }
    if (this.q.USERNAME != undefined) {
      if (query != '') {
        query += ' and DONVI=' + this.q.DONVI;
      }
      else {
        query += 'DONVI=' + this.q.DONVI;
      }
    }
    if (this.q.LOAICAN != undefined) {
      if (query != '') {
        query += ' and LOAICAN=' + this.q.LOAICAN;
      }
      else {
        query += 'LOAICAN=' + this.q.LOAICAN;
      }
    }
    if (query == '')
      this.paging.query = ' (1=1) ';
    else
      this.paging.query = query;

    if (this.q.DONVI == null)
      this.q.DONVI = undefined;
   
    //this.GetListXeVaoRa();
    
//    this.QueryChanged();
    this.listXeVaoRa = [];
    //this.http.get('/api/branch/GetXeVaoRa?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=0' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&BIENSO=' + this.q.BIENXE + '&TENVATLIEU=' + this.q.TENVATLIEU + '&TENNV=' + this.q.USERNAME + '&DONVI=' + this.q.DONVI + '&LOAICAN=' + this.q.LOAICAN + '&XERACHUWA=' + this.q.XERACHUWA + '&tungaystr=' + this.q.tungay + '&denngaystr=' + this.q.denngay, this.httpOptions).subscribe(
    this.http.get('/api/branch/GetXeVaoRa?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=0' +  '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&BIENSO=' + this.q.BIENXE + '&TENVATLIEU=' + this.q.TENVATLIEU + '&TENNV=' + this.q.USERNAME + '&DONVI=' + this.q.DONVI + '&LOAICAN=' + this.q.LOAICAN + '&XERACHUWA=' + this.q.XERACHUWA + '&tungaystr=' + this.q.tungay + '&denngaystr=' + this.q.denngay, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listXeVaoRa = res["data"];
          this.paging.item_count = res["metadata"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  SortTable(str) {
    let First = "";
    let Last = "";
    if (this.paging.order_by != "") {
      First = this.paging.order_by.split(" ")[0];
      Last = this.paging.order_by.split(" ")[1];
    }

    if (First != str) {
      this.paging.order_by = str + " Desc";
    }
    else {
      Last = Last == "Asc" ? "Desc" : "Asc";
      this.paging.order_by = str + " " + Last;
    }

    /*this.GetListSlide();*/
  }

  GetClassSortTable(str) {
    if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
      return "sorting";
    }
    else {
      if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
      else return "sorting_asc";
    }
  }
}
