import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { Order, OrderItem, NhapKho, OrderTram } from '../../data/model';
import { OrderStatus, PaymentOrderStatus } from '../../data/const';
import { debug } from 'util';
import { forEach } from '@angular/router/src/utils/collection';
import { ActivatedRoute } from '@angular/router';
import { Paging, QueryFilter } from '../../data/dt';
import { DatePipe } from '@angular/common';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { CommonService } from '../../service/common.service';
import languages from '../../../assets/i18n/languages.json';
import { TranslateService } from '@ngx-translate/core';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: ' HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
@Component({
  selector: 'app-importmanagement',
  templateUrl: './importmanagement.component.html',
  styleUrls: ['./importmanagement.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ]
})

export class ImportManagement implements OnInit {
  @ViewChild('BranchModal') public BranchModal: ModalDirective;
  @ViewChild('ViewModal') public viewModal: ModalDirective;

  public paging: any;
  public pagingItem: any;
  public q: any;
  
  public listOrder = [];
  public Tonkho: NhapKho;
  public dataSum = {};
  public listOrderBranch = [];
  public listThongKe = [];
  public listCompany = [];
  public listBranchSearch = [];
  public listCuaVL = [];
  public listNV = [];
  public listDonHang = [];
  public ckeConfig: any;
  public cuavatlieu1: boolean;
  public cuavatlieu2: boolean;
  public cuavatlieu3: boolean;
  public cuavatlieu4: boolean;
  public cuavatlieu5: boolean;
  public cuavatlieu6: boolean;
  public cuavatlieu7: boolean;
  public cuavatlieu8: boolean;
  public cuavatlieu9: boolean;
  public cuavatlieu10: boolean;
  public cuavatlieu11: boolean;
  public cuavatlieu12: boolean;
  public kieudulieu1: boolean;
  public kieudulieu2: boolean;
  public kieudulieu: string;
  public cuavatlieu: string;
  public LOAIPHIEU: string;
  public TENCUAVL: string;

  public ItemTram: OrderTram;
  public listCuaVLTram: any;
  public progress: number;
  TypeTram: number;
  TypePhieu: number;
  isdisabled: boolean;
  public Item: Order;
 
  public httpOptions: any;
  public listOrderStatus = OrderStatus;
  public listPaymentOrderStatus = PaymentOrderStatus;
  public role: number;
  companyId: number;
  BranchId: string;
  UserId: number;
  public companyselect: number;
  public companyselectsearch: number;
  public totalMETKHOIDATHANG: number;
  public totalMETKHOITICHLUY: number;

  public total : number = 1;

  PriceCurrencyMaskConfig = {
    align: "left",
    allowNegative: false,
    decimal: ".",
    precision: 0,
    prefix: "",
    suffix: " VNĐ",
    thousands: ","
  };
  public lstLanguage: Array<string> = languages;
  constructor(
    public http: HttpClient,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    public toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    public commonService: CommonService,
    public translate: TranslateService
  ) {
    translate.addLangs(this.lstLanguage);
    this.Item = new Order();
    this.Tonkho = new NhapKho();
    this.cuavatlieu1 = true;
    this.cuavatlieu2 = true;
    this.cuavatlieu3 = true;
    this.cuavatlieu4 = true;
    this.cuavatlieu5 = true;
    this.cuavatlieu6 = true;
    this.cuavatlieu7 = true;
    this.cuavatlieu8 = true;
    this.cuavatlieu9 = true;
    this.cuavatlieu10 = true;
    this.cuavatlieu11 = true;
    this.cuavatlieu12 = true;
    this.kieudulieu1 = true;
    this.kieudulieu2 = true;
   // this.Tonkho = new 

    this.paging = {
      page: 1,
      page_size: 10,
      query: '1=1',
      order_by: 'NGAYDATHANG Desc',
      item_count: 0
    };

    this.q = {
      txtSearch: '',
      tungay: '',
      denngay: '',
      TENCUAVL: '',
      LOAIPHIEU: ''
    }

    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      })
    }
  }

  //Mở modal thêm mới
  OpenBranchModal(itemtram) {

    this.ItemTram = new OrderTram();
    this.ItemTram.TypePhieu = 1;
    this.progress = undefined
   // this.listCuaVLTram = this.listCuaVL;
    if (itemtram != undefined)
    {
      this.isdisabled = true;
      this.ItemTram = JSON.parse(JSON.stringify(itemtram));
      this.http.get('/api/ImportManagement/GetDonHangById/?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&KDL=0' + '&donhangID=' + itemtram.OrderTramId, this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.ItemTram = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        }
      );
    }
    else {
    //  this.ItemTram = JSON.parse(JSON.stringify(itemtram));
      this.http.get('/api/ImportManagement/GetDonHangById/?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&KDL=0' + '&donhangID=0', this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.ItemTram = res["data"];
            this.ItemTram.TypePhieu = 1;
          }
        },
        (err) => {
          console.log("Error: connect to API");
        }
      );
      this.isdisabled = false;
    }
    this.BranchModal.show();   
  }

  getQueryPar() {
    var str = '0';
    var str1 = '0';
    if (this.cuavatlieu1 == true) {
      str += ',1';
    }
    if (this.cuavatlieu2 == true) {
      str += ',2';
    }
    if (this.cuavatlieu3 == true) {
      str += ',3';
    }
    if (this.cuavatlieu4 == true) {
      str += ',4';
    }
    if (this.cuavatlieu5 == true) {
      str += ',5';
    }
    if (this.cuavatlieu6 == true) {
      str += ',6';
    }
    if (this.cuavatlieu7 == true) {
      str += ',7';
    }
    if (this.cuavatlieu8 == true) {
      str += ',8';
    }
    if (this.cuavatlieu9 == true) {
      str += ',9';
    }
    if (this.cuavatlieu10 == true) {
      str += ',10';
    }
    if (this.cuavatlieu11 == true) {
      str += ',11';
    }
    if (this.cuavatlieu12 == true) {
      str += ',12';
    }
    if (this.kieudulieu1 == true) {
      str1 += ',1';
    }
    if (this.kieudulieu2 == true) {
      str1 += ',2';
    }
    this.cuavatlieu = str;
    this.kieudulieu = str1;
    let query = '';
    if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
      if (query != '') {
        query += " and 1=1";
      }
      else {
        query += " 1=1 ";
      }
    }

    if (this.q.fromdate != undefined && this.q.todate != undefined && this.q.fromdate != '' && this.q.todate != '') {
      if (query != '') {
        query += " AND ( CreatedAt >= convert(datetime ,'" + this.q.fromdate + " 00:00:00.000') AND CreatedAt <= convert(datetime ,'" + this.q.todate + " 23:59:59.999') )";
      }
      else {
        query += " AND ( CreatedAt >= convert(datetime ,'" + this.q.fromdate + " 00:00:00.000') AND CreatedAt <= convert(datetime ,'" + this.q.todate + " 23:59:59.999') )";
      }
    }
   // this.getQueryPar();
    //if (this.q.TENNV != undefined && this.q.TENNV != '') {
    //  if (query != '') {
    //    query += " AND nv.TENNV = N'" + this.q.TENNV + "'";
    //  }
    //  else {
    //    query += "nv.TENNV = N'" + this.q.TENNV + "'";
    //  }
    //}

    if (this.role == 3) {
      this.paging.CompanyId = this.companyId;
    }
    else {
      if (this.q.CompanyId != undefined) {
        this.paging.CompanyId = this.q.CompanyId;
      }
      else {
        this.paging.CompanyId = 0;
      }
    }

    if (this.q.Branchlist != undefined) {
      this.paging.Branchid = this.q.Branchlist;
      //this.paging.Branchid = '';
      //this.q.Branchlist.forEach((item, index) => {
      //  if (item != '') {
      //    this.paging.Branchid = this.paging.Branchid + item + ',';
      //  }
      //});
    }
    else {
      this.paging.Branchid = '';
      if (this.role != 1) {
        this.listBranchSearch.forEach((item, index) => {
          if (item != '') {
            this.paging.Branchid = this.paging.Branchid + item["BranchId"] + ',';
          }
        });
      }
    }
    if (query == '')
      this.paging.query = '1=1';
    else
      this.paging.query = query;
    
  }
  ngOnInit() {

  
    this.GetListBranch();
    this.totalMETKHOIDATHANG = 0;
    this.totalMETKHOITICHLUY = 0;
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };
    var json = JSON.parse(localStorage.getItem('roles'));
    this.companyId = parseInt(localStorage.getItem('companyId'));
    this.UserId = parseInt(localStorage.getItem('userId'));
    this.BranchId = localStorage.getItem('BranchId');
    this.companyselect = -1;
    if (json.length > 0) {
      for (var i = 0; i < json.length; i++) {
        this.role = json[i].RoleId
      }
    }

    if (this.role == 3) {
      this.paging.CompanyId = this.companyId;
    }
    else {
      this.paging.CompanyId = 0;
    }

    this.paging.Branchid = '';
    if (this.role == 3) {
      this.GetListBranchSearchStart();
    }
    if (this.role == 4) {
      this.GetListBranchCTCon();
      this.paging.Branchid = this.BranchId;
    }
    if (this.role == 1) {
      this.GetListCompany();
    }
    if (this.role != 1) {
      this.GetListBranchSearchStart();
    }
    this.paging.query = "DATEPART(dy,GETDATE()) = DATEPART(dy,NGAYDATHANG) AND DATEPART(yy,GETDATE()) = DATEPART(yy,NGAYDATHANG)";
   // let todaynew = new Date().toISOString().substr(0, 10);
    let todaynew  = new Date();
    //this.q.tungay = this.datepipe.transform(today, 'yyyy-MM-dd');
    this.q.fromdate = this.datepipe.transform(todaynew, 'yyyy-MM-dd');
    this.q.todate = this.datepipe.transform(todaynew, 'yyyy-MM-dd');
    this.q.ckbNhapKho = this.kieudulieu1;
    this.q.ckTinhKho = this.kieudulieu2;
    
    if (localStorage.getItem('getlinkd') == "1") {
      if (localStorage.getItem('thoigiand') == '1') {
        let today = new Date().toISOString().substr(0, 10);
        this.q.fromdate = today;
        this.q.todate = today;
        this.paging.query = "DATEPART(dy,GETDATE()) = DATEPART(dy,NGAYDATHANG) AND DATEPART(yy,GETDATE()) = DATEPART(yy,NGAYDATHANG)";
      }
      if (localStorage.getItem('thoigiand') == '2') {
        let today = new Date();
        this.q.fromdate = new Date(today.setDate(today.getDate() - today.getDay() + 1)).toISOString().substr(0, 10);
        this.q.todate = new Date(today.setDate(today.getDate() - today.getDay() + 7)).toISOString().substr(0, 10);
        this.paging.query = "DATEPART(wk,GETDATE()) = DATEPART(wk,NGAYDATHANG) AND DATEPART(yy,GETDATE()) = DATEPART(yy,NGAYDATHANG)";
      }
      if (localStorage.getItem('thoigiand') == '3') {
        let today = new Date();
        this.q.fromdate = new Date(today.setDate(today.getDate() - today.getDay() + 1 - 7)).toISOString().substr(0, 10);
        let today1 = new Date();
        this.q.todate = new Date(today1.setDate(today1.getDate() - today1.getDay())).toISOString().substr(0, 10);
        this.paging.query = "(DATEPART(wk,GETDATE()) -1) = DATEPART(wk,NGAYDATHANG) AND DATEPART(yy,GETDATE()) = DATEPART(yy,NGAYDATHANG)";
      }
      if (localStorage.getItem('thoigiand') == '4') {
        var today = new Date();
        var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(),2);
        this.q.fromdate = lastDayOfMonth.toISOString().substr(0, 10);

        var today1 = new Date();
        var lastDayOfMonth1 = new Date(today1.getFullYear(), today1.getMonth() + 1, 1);
        this.q.todate = lastDayOfMonth1.toISOString().substr(0, 10);
        this.paging.query = "DATEPART(mm,GETDATE()) = DATEPART(mm,NGAYDATHANG) AND DATEPART(yy,GETDATE()) = DATEPART(yy,NGAYDATHANG)";
      }
    }
    this.GetlistKH();
 //   this.GetListOrder();
    this.GetListOrderBarch();

  }
  GetListBranch() {
    //if (this.role != 1) {
    //  this.paging.query += ' and CompanyId = ' + this.companyId;
    //}
    this.ItemTram = new OrderTram();
    this.paging = new Paging();
    this.paging.page = 1;
    this.paging.page_size = 10;
    this.paging.query = "1=1";
    this.paging.order_by = "OrderTramId Desc";
    this.paging.item_count = 0;
    this.QueryChangedCuaVL();
    this.http.get('/api/ImportManagement/GetByPageDonHang/?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&KDL=' + this.kieudulieu + '&CVL=' + this.cuavatlieu + '&tungay=' + this.q.fromdate + '&denngay=' + this.q.todate, this.httpOptions).subscribe(
   //this.http.get('/api/ImportManagement/GetByPageDonHang/?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&KDL=', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listDonHang = res["data"];
          this.paging.item_count = res["metadata"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  GetCuaVL() {

    this.QueryChangedCuaVL();

    this.http.get('/api/ImportManagement/GetCuaVL/?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&KDL=' , this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listCuaVL = res["data"];
          this.listThongKe = res["data1"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
    let query = '';
    if (this.q.fromdate != undefined && this.q.fromdate != undefined && this.q.todate != '' && this.q.todate != '') {
      if (query != '') {
        query += " AND ( CreatedAt >= convert(datetime ,'" + this.q.fromdate + " 00:00:00.000') AND CreatedAt <= convert(datetime ,'" + this.q.todate + " 23:59:59.999') )";
      }
      else {
        query += " AND ( CreatedAt >= convert(datetime ,'" + this.q.fromdate + " 00:00:00.000') AND CreatedAt <= convert(datetime ,'" + this.q.todate + " 23:59:59.999') )";
      }
    }
    this.paging.query = query;
    this.http.get('/api/ImportManagement/GetByPageDonHang/?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&KDL=0' + '&tungay=' + this.q.fromdate + '&denngay=' + this.q.todate, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listDonHang = res["data"];
          this.listThongKe = res["data1"];
          this.paging.item_count = res["metadata"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  //test
  TestChange(i) {
    if (i == 1) {
      this.cuavatlieu1 = !this.cuavatlieu1;
    } else if (i == 2) {
      this.cuavatlieu2 = !this.cuavatlieu2;
    } else if (i == 3) {
      this.cuavatlieu3 = !this.cuavatlieu3;
    } else if (i == 4) {
      this.cuavatlieu4 = !this.cuavatlieu4;
    } else if (i == 5) {
      this.cuavatlieu5 = !this.cuavatlieu5;
    } else if (i == 6) {
      this.cuavatlieu6 = !this.cuavatlieu6;
    } else if (i == 7) {
      this.cuavatlieu7 = !this.cuavatlieu7;
    } else if (i == 8) {
      this.cuavatlieu8 = !this.cuavatlieu8;
    } else if (i == 9) {
      this.cuavatlieu9 = !this.cuavatlieu9;
    } else if (i == 10) {
      this.cuavatlieu10 = !this.cuavatlieu10;
    } else if (i == 11) {
      this.cuavatlieu11 = !this.cuavatlieu11;
    } else if (i == 12) {
      this.cuavatlieu12 = !this.cuavatlieu12;
    }
  }
  //Get danh sách danh mục đơn hàng
  GetListOrder() {
 

    console.log("Query moi" + '&TENCUAVL=' + this.q.TENCUAVL + '&LOAIPHIEU=' + this.q.LOAIPHIEU);
    
    this.http.get('/api/ImportManagement/GetByPageDonHang/?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&KDL=' + this.kieudulieu + '&CVL=' + this.cuavatlieu + '&tungay=' + this.q.fromdate + '&denngay=' + this.q.todate + '&tencuavatlieu=' + this.q.TENCUAVL + '&LOAIPHIEU=' + this.q.LOAIPHIEU , this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listDonHang = res["data"];
          this.listThongKe = res["data1"];
          this.paging.item_count = res["metadata"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  ExportExcel() {
    if (this.paging.CompanyId == undefined) {
      this.paging.CompanyId = 0;
    }
    if (this.q.Branchlist == undefined) {
      //this.toastWarning("Bạn chưa chọn trạm trộn!");
    } else {
      this.getQueryPar();
      fetch('/api/ImportManagement/GetReport?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&KDL=' + this.kieudulieu + '&CVL=' + this.cuavatlieu + '&tungay=' + this.q.fromdate + '&denngay=' + this.q.todate, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("access_token")
        }),
      })
        .then(function (response) {
          var blob = response.blob();
          return blob;
        }).then(blob => {
          var DateObj = new Date();
          var date = ('0' + DateObj.getDate()).slice(-2) + '_' + ('0' + (DateObj.getMonth() + 1)).slice(-2) + '_' + DateObj.getFullYear();
          let tenchinhanh = this.listBranchSearch.find(x => x.BranchId == this.paging.Branchid);
          this.DownloadFile(blob, this.commonService.ConvertUrl(tenchinhanh.Name) + "_Quan_ly_vat_lieu" + date + ".xlsx", 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        });
    }
    
  }
  DownloadFile(data: Blob, filename: string, mime: string): void {
    var blob = new Blob([data], { type: mime || 'application/octet-stream' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE workaround for "HTML7007: One or more blob URLs were 
      // revoked by closing the blob for which they were created. 
      // These URLs will no longer resolve as the data backing 
      // the URL has been freed."
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      var blobURL = window.URL.createObjectURL(blob);
      var tempLink = document.createElement('a'); 
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);
      tempLink.setAttribute('target', '_blank');
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    }
  }
  //Get danh sách danh mục đơn hàng
  GetListOrderBarch() {
    //this.http.get('/api/ImportManagement/GetByBranch?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid, this.httpOptions).subscribe(
    //  (res) => {
    //    if (res["meta"]["error_code"] == 200) {
    //      this.listOrderBranch = res["data"];
    //      this.paging.item_count = res["metadata"];
    //      this.totalMETKHOIDATHANG = 0;
    //      this.totalMETKHOITICHLUY = 0;
    //      for (let index in this.listOrderBranch) {
    //        this.totalMETKHOIDATHANG = this.totalMETKHOIDATHANG + this.listOrderBranch[index].METKHOIDATHANG;
    //        this.totalMETKHOITICHLUY = this.totalMETKHOITICHLUY + this.listOrderBranch[index].METKHOITICHLUY;
    //      };
    //    }
    //  },
    //  (err) => {
    //    console.log("Error: connect to API");
    //  }
    //);
  }
  // Get danh sách khách hàng
  GetlistKH() {
    //this.http.get('/api/ImportManagement/GetNV?companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid, this.httpOptions).subscribe(
    //  (res) => {
    //    if (res["meta"]["error_code"] == 200) {
    //      this.listNV = res["data"];
    //    }
    //  },
    //  (err) => {
    //    console.log("Error: connect to API");
    //  }
    //);
  }
  GetListCompany() {
    this.http.get('/api/company/GetByPage?page=1&query=1=1&order_by=', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listCompany = res["data"];
          if (localStorage.getItem('congtyd') != 'undefined') {
            var index = this.listCompany.find(x => x.CompanyId == parseInt(localStorage.getItem('congtyd')));
            this.q.CompanyId = index.CompanyId;
            this.paging.CompanyId = parseInt(localStorage.getItem('congtyd'));
            this.companyId = parseInt(localStorage.getItem('congtyd'));
            this.GetListBranchSearchStart();
            this.GetListOrder();
            this.GetListOrderBarch();
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  GetListBranchSearchStart() {
    this.http.get('/api/branch/GetByPage?page=1&query=CompanyId = ' + this.companyId + ' &order_by=', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listBranchSearch = res["data"];
            let localtramtron = localStorage.getItem("BranchId");
            if (localtramtron != "undefined") {
              let ids = localtramtron.split(",").map(Number);
              this.listBranchSearch = this.listBranchSearch.filter(
                (x) => ids.includes(x.BranchId)
              );
            }
          if (localStorage.getItem('tramtrond') != 'undefined') {
            this.q.Branchlist = [];
            let lstnhombenh = localStorage.getItem('tramtrond').split(',');
            lstnhombenh.forEach(element => {
              this.q.Branchlist.push(parseInt(element));
            });
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  GetListBranchCTCon() {
    this.http.get('/api/ImportManagement/GetByUser?id=' + this.UserId, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listBranchSearch = res["data"];
          if (localStorage.getItem('tramtrond') != 'undefined') {
            this.q.Branchlist = [];
            let lstnhombenh = localStorage.getItem('tramtrond').split(',');
            lstnhombenh.forEach(element => {
              this.q.Branchlist.push(parseInt(element));
            });
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  GetListBranchSearch() {
    this.http.get('/api/branch/GetByPage?page=1&query=CompanyId = ' + this.companyselectsearch + ' &order_by=', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listBranchSearch = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  //Chuyển trang
  PageChanged(event) {
    this.paging.page = event.page;
    console.log("Page log" + this.paging.page );
   // this.GetListBranch();
    //this.GetListOrder();

    this.http.get('/api/ImportManagement/GetByPageDonHang/?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&KDL=' + this.kieudulieu + '&CVL=' + this.cuavatlieu + '&tungay=' + this.q.fromdate + '&denngay=' + this.q.todate, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listDonHang = res["data"];
          this.paging.item_count = res["metadata"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  PageSizeChanged() {
    this.paging.page =1;
    console.log("Page log" + this.paging.page);
    // this.GetListBranch();
    //this.GetListOrder();

    this.http.get('/api/ImportManagement/GetByPageDonHang/?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + '&companyid=' + this.paging.CompanyId + '&Branchlist=' + this.paging.Branchid + '&KDL=' + this.kieudulieu + '&CVL=' + this.cuavatlieu + '&tungay=' + this.q.fromdate + '&denngay=' + this.q.todate, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listDonHang = res["data"];
          this.paging.item_count = res["metadata"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  //Toast cảnh báo
  toastWarning(msg): void {
    this.toastr.warning(msg, this.translate.instant('TOAST.WARNING'));
  }

  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, this.translate.instant('TOAST.SUCCESS'));
  }

  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, this.translate.instant('TOAST.ERROR'));
  }
  //
  QueryChanged() {
    
    let query = '';
    if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
      if (query != '') {
        query += " and 1=1";
      }
      else {
        query += " 1=1 ";
      }
    }

    if (this.q.fromdate != undefined && this.q.todate != undefined && this.q.fromdate != '' && this.q.todate != '') {
      if (query != '') {
        query += " AND ( CreatedAt >= convert(datetime ,'" + this.q.fromdate + " 00:00:00.000') AND CreatedAt <= convert(datetime ,'" + this.q.todate + " 23:59:59.999') )";
      }
      else {
        query += " AND ( CreatedAt >= convert(datetime ,'" + this.q.fromdate + " 00:00:00.000') AND CreatedAt <= convert(datetime ,'" + this.q.todate + " 23:59:59.999') )";
      }
    }
    this.getQueryPar();
    //if (this.q.TENNV != undefined && this.q.TENNV != '') {
    //  if (query != '') {
    //    query += " AND nv.TENNV = N'" + this.q.TENNV + "'";
    //  }
    //  else {
    //    query += "nv.TENNV = N'" + this.q.TENNV + "'";
    //  }
    //}

    if (this.role == 3) {
      this.paging.CompanyId = this.companyId;
    }
    else {
      if (this.q.CompanyId != undefined) {
        this.paging.CompanyId = this.q.CompanyId;
      }
      else {
        this.paging.CompanyId = 0;
      }
    }

    if (this.q.Branchlist != undefined) {
      this.paging.Branchid  =  this.q.Branchlist;
      //this.paging.Branchid = '';
      //this.q.Branchlist.forEach((item, index) => {
      //  if (item != '') {
      //    this.paging.Branchid = this.paging.Branchid + item + ',';
      //  }
      //});
    }
    else {
      this.paging.Branchid = '';
      if (this.role != 1) {
        this.listBranchSearch.forEach((item, index) => {
          if (item != '') {
            this.paging.Branchid = this.paging.Branchid + item["BranchId"] + ',';
          }
        });
      }
    }
    if (query == '')
      this.paging.query = '1=1';
    else
      this.paging.query = query;
    if (this.q.Branchlist == undefined) {
      this.toastWarning(this.translate.instant('TOAST.IMPORTMANAGEMENT.TOAST_1'));
    } else {
      this.GetListOrder();
    }
    
   // this.GetListOrderBarch();
  }

  QueryChangedCuaVL() {

    let query = '';
    if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
      if (query != '') {
        query += " and 1=1";
      }
      else {
        query += " 1=1 ";
      }
    }

    if (this.q.fromdate != undefined && this.q.todate != undefined && this.q.fromdate != '' && this.q.todate != '') {
      if (query != '') {
        query += " AND ( CreatedAt >= convert(datetime ,'" + this.q.fromdate + " 00:00:00.000') AND CreatedAt <= convert(datetime ,'" + this.q.todate + " 23:59:59.999') )";
      }
      else {
        query += " AND ( CreatedAt >= convert(datetime ,'" + this.q.fromdate + " 00:00:00.000') AND CreatedAt <= convert(datetime ,'" + this.q.todate + " 23:59:59.999') )";
      }
    }
    this.getQueryPar();
    //if (this.q.TENNV != undefined && this.q.TENNV != '') {
    //  if (query != '') {
    //    query += " AND nv.TENNV = N'" + this.q.TENNV + "'";
    //  }
    //  else {
    //    query += "nv.TENNV = N'" + this.q.TENNV + "'";
    //  }
    //}

    if (this.role == 3) {
      this.paging.CompanyId = this.companyId;
    }
    else {
      if (this.q.CompanyId != undefined) {
        this.paging.CompanyId = this.q.CompanyId;
      }
      else {
        this.paging.CompanyId = 0;
      }
    }

    if (this.q.Branchlist != undefined) {
      this.paging.Branchid = this.q.Branchlist;
      //this.paging.Branchid = '';
      //this.q.Branchlist.forEach((item, index) => {
      //  if (item != '') {
      //    this.paging.Branchid = this.paging.Branchid + item + ',';
      //  }
      //});
    }
    else {
      this.paging.Branchid = '';
      if (this.role != 1) {
        this.listBranchSearch.forEach((item, index) => {
          if (item != '') {
            this.paging.Branchid = this.paging.Branchid + item["BranchId"] + ',';
          }
        });
      }
    }
    if (query == '')
      this.paging.query = '1=1';
    else
      this.paging.query = query;
    if (this.q.Branchlist == undefined) {
      //this.toastWarning(this.translate.instant('TOAST.IMPORTMANAGEMENT.TOAST_1'));
    } else {
  //    this.GetListOrder();
    }

    // this.GetListOrderBarch();
  }
  //
  GetBranch() {
    this.companyselectsearch = this.q.CompanyId;
    this.listBranchSearch = [];
    this.q.BranchId = null;
    this.GetListBranchSearch();
    this.paging.CompanyId = this.q.CompanyId;
  }
  //Open modal view
  OpenViewModal(item) {
    this.Item = new Order();
    this.Item = Object.assign({}, item);
    this.viewModal.show();
  }

  //Thêm mới khách hàng
  SaveBranch() {
    var listQuantity = this.ItemTram.listOrderTramItems;
    var getTypePhieu = this.ItemTram.TypePhieu;
    var getDate = this.ItemTram.CreatedAt;
    if (getTypePhieu == 1 || getTypePhieu == 2) {
      for (var key  in  listQuantity) {
        //if (listQuantity[key].Quantity == null || listQuantity[key].Quantity == undefined) {
        //  let notification = "Không được để trống " + listQuantity[key].TENCUAVL;
        //  this.toastError(notification);
        //  return;
        //}
        if (listQuantity[key].Quantity < 0) {
          let notification = listQuantity[key].TENCUAVL + " " + this.translate.instant('TOAST.IMPORTMANAGEMENT.TOAST_2');
          this.toastError(notification);
          return;
        }
      }
    }
    console.log('Date',getDate);
    //else if (getTypePhieu == 3) {
    //  for (var key in listQuantity) {
    //    if (listQuantity[key].Quantity == null || listQuantity[key].Quantity == undefined) {
    //      let notification = "Không được để trống " + listQuantity[key].TENCUAVL;
    //      this.toastError(notification);
    //      return;
    //    }
    //    if (listQuantity[key].Quantity > 0) {
    //      let notification = listQuantity[key].TENCUAVL + " Phải là số âm";
    //      this.toastError(notification);
    //      return;
    //    }
    //  }
    //}
    
    let mtt = this.q.Branchlist;
    console.log(mtt);
    if (this.q.Branchlist == undefined || this.q.Branchlist == '') {
      this.toastWarning(this.translate.instant('TOAST.IMPORTMANAGEMENT.TOAST_3'));
      return;
    }
    //else 
    //if (this.ItemTram.Code == undefined || this.ItemTram.Code == '') {
    //  this.toastWarning("Chưa nhập mã đơn hàng!");
    //  return;
    //} else if (this.ItemTram.Code.replace(/ /g, '') == '') {
    //  this.toastWarning("Chưa nhập mã!");
    //  return;
    //} else if (this.ItemTram.Name == undefined || this.ItemTram.Name == '') {
    //  this.toastWarning("Chưa nhập tên đơn hàng!");
    //  return;
    //} else if (this.ItemTram.Name.replace(/ /g, '') == '') {
    //  this.toastWarning("Chưa nhập tên!");
    //  return;
    //}

    this.QueryChangedCuaVL();
  
    /*this.ItemTram.UserId = parseInt(localStorage.getItem("userId"));*/
    this.ItemTram.BrandId = this.q.Branchlist;
   // if (this.ItemTram.OrderTramId == undefined)
    {
      this.ItemTram.UserId = this.UserId;
    //  this.ItemTram.listOrderTramItems = this.listCuaVLTram;
      //this.ItemTram.BrandId = mtt;
      let obj = JSON.parse(JSON.stringify(this.ItemTram));
      
      //let obj1 = JSON.parse(JSON.stringify(this.listCuaVLTram));

      this.http.post('/api/ImportManagement', obj , this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.GetListBranch();
            this.BranchModal.hide();
            this.GetCuaVL();
            this.toastSuccess(this.translate.instant('TOAST.NOITIFY_SUCCESS'));
          }
          else if (res["meta"]["error_code"] == 212) {
            this.toastWarning(this.translate.instant('TOAST.IMPORTMANAGEMENT.TOAST_4'));
          }
          else {
            this.toastError(res["meta"]["error_message"]);
          }
        },
        (err) => {
          this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
        }
      );
    }
    
    //else {
    //  this.ItemTram.listOrderTramItems = this.listCuaVLTram;
    //  debugger;
    //  let obj = JSON.parse(JSON.stringify(this.ItemTram));
    //  this.ItemTram.BrandId = this.paging.Branchid;
    //  this.http.put('/api/OrderTram/' + this.ItemTram.OrderTramId, this.ItemTram, this.httpOptions).subscribe(
    //    (res) => {
    //      if (res["meta"]["error_code"] == 200) {
    //        this.GetListBranch();
    //        this.BranchModal.hide();
    //        this.toastSuccess(res["meta"]["error_message"]);
    //      }
    //      else if (res["meta"]["error_code"] == 212) {
    //        this.toastWarning("Mã chi nhánh đã tồn tại!");
    //      }
    //      else {
    //        this.toastError(res["meta"]["error_message"]);
    //      }
    //    },
    //    (err) => {
    //      this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
    //    }
    //  );
    //}
  }

  SortTable(str) {
    let First = "";
    let Last = "";
    if (this.paging.order_by != "") {
      First = this.paging.order_by.split(" ")[0];
      Last = this.paging.order_by.split(" ")[1];
    }

    if (First != str) {
      this.paging.order_by = str + " Desc";
    }
    else {
      Last = Last == "Asc" ? "Desc" : "Asc";
      this.paging.order_by = str + " " + Last;
    }
    this.GetCuaVL();
    this.GetListOrder();
  }

  GetClassSortTable(str) {
    if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
      return "sorting";
    }
    else {
      if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
      else return "sorting_asc";
    }
  }

  //Popup xác nhận xóa
  ShowConfirmDelete(Id) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: this.translate.instant('TOAST.CONFIM'),
      childComponent: SimpleModalComponent,
      data: {
        text: this.translate.instant('TOAST.CONFIM_DELETE')
      },
      actionButtons: [
        {
          text: this.translate.instant('TOAST.YES'),
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.Delete(Id, this.q.Branchlist);
          }
        },
        {
          text: this.translate.instant('TOAST.CLOSE'),
          buttonClass: 'btn btn-default',

        }
      ],
    });
  }

  Delete(Id,bran) {
    this.http.delete('/api/ImportManagement/' + Id + '/' + bran, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.QueryChanged();
          this.viewRef.clear();
          this.GetCuaVL();
          this.toastSuccess(this.translate.instant('TOAST.NOITIFY_DELETE'));
        }
        else {
          this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
        }
      },
      (err) => {
        this.toastError(this.translate.instant('TOAST.NOITiFY_ERROR'));
      }
    );
  }

  changeValue = (index : any) => {
    console.log(this.ItemTram.listOrderTramItems[index].Price);
    console.log(this.ItemTram.listOrderTramItems[index].Conversion_Volume);
    this.ItemTram.listOrderTramItems[index].Total_Money =  this.ItemTram.listOrderTramItems[index].Price * this.ItemTram.listOrderTramItems[index].Conversion_Volume;
  }
}
