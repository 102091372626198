import { Component, OnInit } from "@angular/core";
import {
  getLocaleCurrencyName,
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { ModalDirective } from "ngx-bootstrap/modal";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ModalDialogService, SimpleModalComponent } from "ngx-modal-dialog";
import { ToastrService } from "ngx-toastr";
import { Order, OrderItem } from "../../data/model";
import { debug } from "util";
import { DatePipe } from "@angular/common";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@Component({
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
  templateUrl: "dashboard.component.html",
})
export class DashboardComponent implements OnInit {
  public language_code: string = localStorage.getItem("langSelect");

  public paging: any;
  public pagingItem: any;
  public q: any;
  public listOrder = [];
  public listOrderCharH = [
    { NGAYDATHANG: "0H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "1H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "2H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "3H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "4H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "5H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "6H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "7H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "8H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "9H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "10H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "11H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "12H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "13H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "14H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "15H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "16H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "17H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "18H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "19H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "20H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "21H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "22H", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "23H", METKHOITICHLUY: 0 },
  ];

  public listOrderChartW = [
    { NGAYDATHANG: "Thứ 2", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "Thứ 3", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "Thứ 4", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "Thứ 5", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "Thứ 6", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "Thứ 7", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "Chủ nhật", METKHOITICHLUY: 0 },
  ];
  public listOrderChartD = [
    { NGAYDATHANG: "1", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "2", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "3", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "4", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "5", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "6", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "7", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "8", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "9", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "10", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "11", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "12", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "13", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "14", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "15", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "16", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "17", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "18", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "19", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "20", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "21", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "22", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "23", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "24", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "25", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "26", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "27", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "28", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "29", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "30", METKHOITICHLUY: 0 },
    { NGAYDATHANG: "31", METKHOITICHLUY: 0 },
  ];
  public listCompany = [];
  public listBranchSearch = [];
  public ckeConfig: any;
  public barChartLabels: string[] = [];

  public httpOptions: any;
  public role: number;
  companyId: number;
  BranchId: string;
  UserId: number;
  public totalName: string[] = [];
  public totaldonhang: number;

  public totalTENNV: number;
  public totalmac: number;
  public totalxe: number;
  public totalMETKHOITICHLUY: string[] = [];

  public companyselectsearch: number;

  constructor(
    public http: HttpClient,
    public modalDialogService: ModalDialogService,
    public toastr: ToastrService,
    public datepipe: DatePipe,
    private translate: TranslateService
  ) {
    this.paging = {
      page: 1,
      page_size: 10,
      query: "1=1",
      querychar: "1=1",
      order_by: "NGAYDATHANG Desc",
      item_count: 0,
    };

    this.q = {
      txtSearch: "",
    };

    console.log(this.translate.instant("DASHBOARD.TONG_KHOI_LUONG"));
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: "bearer " + localStorage.getItem("access_token"),
      }),
    };
  }
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      labels: {
        // This more specific font property overrides the global property
        fontColor: "black",
        fontSize: 18,
      },
    },
  };

  public barChartType = "line";
  public barChartLegend = true;

  public barChartData = [
    {
      data: [],
      label: this.translate.instant("DASHBOARD.TONG_KHOI_LUONG") + ": ",
    },
  ];

  ngOnInit(): void {
    localStorage.setItem("thoigiand", undefined);
    localStorage.setItem("congtyd", undefined);
    localStorage.setItem("tramtrond", undefined);
    localStorage.setItem("getlinkd", "0");

    //this.translate.get('vi', 'USER.HEADING').subscribe((res: string) => {
    //  console.log(res);
    //});
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: "divarea",
      forcePasteAsPlainText: true,
    };
    var json = JSON.parse(localStorage.getItem("roles"));
    this.companyId = parseInt(localStorage.getItem("companyId"));
    this.UserId = parseInt(localStorage.getItem("userId"));
    this.BranchId = localStorage.getItem("BranchId");
    if (json.length > 0) {
      for (var i = 0; i < json.length; i++) {
        this.role = json[i].RoleId;
      }
    }
    console.log(this.role);
    this.q.status = "0";
    this.paging.Branchid = "";
    if (this.role == 3) {
      this.paging.CompanyId = this.companyId;
      this.GetListBranchSearchStart();
    } else {
      this.paging.CompanyId = 0;
    }
    if (this.role == 4) {
      this.GetListBranchCTCon();
      this.paging.Branchid = this.BranchId;
    }
    if (this.role != 1) {
      this.GetListBranchSearchStart();
      this.paging.Branchid = this.BranchId;
    }

    //  let today = new Date().toLocaleDateString().substr(0, 10);
    let today = new Date();
    this.q.tungay = this.datepipe.transform(today, "yyyy-MM-dd");
    this.q.denngay = this.datepipe.transform(today, "yyyy-MM-dd");

    this.paging.query =
      "( sa.NGAYDATHANG >= convert(datetime ,'" +
      this.q.tungay +
      " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
      this.q.denngay +
      " 23:59:59.999') )";
    this.paging.querychar =
      "( tr.GIOXONG >= convert(datetime ,'" +
      this.q.tungay +
      " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
      this.q.denngay +
      " 23:59:59.999') )";
    this.GetListOrder();
    this.GetListCompany();
    this.GetListOrderChart();
    this.GetListOrderDH();
  }
  //Get danh sách danh mục đơn hàng
  GetListOrder() {
    this.http
      .get(
        "/api/Dashboard/GetByPage?page=" +
          this.paging.page +
          "&page_size=" +
          this.paging.page_size +
          "&query=" +
          this.paging.querychar +
          "&order_by=" +
          this.paging.order_by +
          "&companyid=" +
          this.paging.CompanyId +
          "&Branchlist=" +
          this.paging.Branchid,
        this.httpOptions
      )
      .subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.listOrder = res["data"];
            this.paging.item_count = res["metadata"];
            this.totalMETKHOITICHLUY = [];
            this.totalName = [];
            for (let index in this.listOrder) {
              if (
                this.totalMETKHOITICHLUY.find(
                  (item) => item == this.listOrder[index].Name
                ) == undefined
              ) {
                this.totalMETKHOITICHLUY.push(this.listOrder[index].Name);
              }
              if (
                this.totalName.find(
                  (item) => item == this.listOrder[index].TENNV
                ) == undefined
              ) {
                this.totalName.push(this.listOrder[index].TENNV);
              }
            }
            this.totalmac = this.totalMETKHOITICHLUY.length;
            this.totalxe = this.totalName.length;
          }
        },
        (err) => {
          console.log("Error: connect to API");
        }
      );
  }
  GetListOrderDH() {
    this.http
      .get(
        "/api/Dashboard/GetByPageDH?page=" +
          this.paging.page +
          "&page_size=" +
          this.paging.page_size +
          "&query=" +
          this.paging.query +
          "&order_by=" +
          this.paging.order_by +
          "&companyid=" +
          this.paging.CompanyId +
          "&Branchlist=" +
          this.paging.Branchid,
        this.httpOptions
      )
      .subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.listOrder = res["data"];
            this.paging.item_count = res["metadata"];
            this.totaldonhang = 0;
            this.totalTENNV = 0;
            for (let index in this.listOrder) {
              this.totaldonhang =
                this.totaldonhang + this.listOrder[index].donhang;
              this.totalTENNV = this.totalTENNV + 1;
            }
          }
        },
        (err) => {
          console.log("Error: connect to API");
        }
      );
  }
  GetListOrderChart() {
    this.http
      .get(
        "/api/Dashboard/GetByPageChart?page=" +
          this.paging.page +
          "&page_size=" +
          this.paging.page_size +
          "&query=" +
          this.paging.querychar +
          "&order_by=" +
          this.paging.order_by +
          "&sort=" +
          this.q.status +
          "&companyid=" +
          this.paging.CompanyId +
          "&Branchlist=" +
          this.paging.Branchid,
        this.httpOptions
      )
      .subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            let data = res["data"];
            //debugger;
            let sum = 0;
            if (this.q.status == "0") {
              this.listOrderCharH.forEach(function (value) {
                value.METKHOITICHLUY = 0;
              });

              if (data != null) {
                let that1 = this;
                data.forEach(function (value) {
                  that1.listOrderCharH.find(
                    (item) => item.NGAYDATHANG == value.NGAYDATHANG
                  ).METKHOITICHLUY = value.METKHOITICHLUY;
                  sum = sum + value.METKHOITICHLUY;
                });
              }
              this.barChartData = [
                {
                  data: this.listOrderCharH.map((x) => x.METKHOITICHLUY),
                  label:
                    this.translate.instant("DASHBOARD.TONG_KHOI_LUONG") +
                    ": " +
                    sum.toFixed(1).toString() +
                    "m3",
                },
              ];
              if (this.barChartLabels.length !== 0) {
                this.barChartLabels.splice(0, 99);
              }
              let that = this;
              this.listOrderCharH
                .map((x) => x.NGAYDATHANG)
                .forEach(function (snapshot) {
                  that.barChartLabels.push(snapshot);
                });
            }
            if (this.q.status == "1") {
              this.listOrderCharH.forEach(function (value) {
                value.METKHOITICHLUY = 0;
              });

              if (data != null) {
                let that1 = this;
                data.forEach(function (value) {
                  that1.listOrderCharH.find(
                    (item) => item.NGAYDATHANG == value.NGAYDATHANG
                  ).METKHOITICHLUY = value.METKHOITICHLUY;
                  sum = sum + value.METKHOITICHLUY;
                });
              }
              this.barChartData = [
                {
                  data: this.listOrderCharH.map((x) => x.METKHOITICHLUY),
                  label:
                    this.translate.instant("DASHBOARD.TONG_KHOI_LUONG") +
                    ": " +
                    sum.toFixed(1).toString() +
                    "m3",
                },
              ];
              if (this.barChartLabels.length !== 0) {
                this.barChartLabels.splice(0, 99);
              }
              let that = this;
              this.listOrderCharH
                .map((x) => x.NGAYDATHANG)
                .forEach(function (snapshot) {
                  that.barChartLabels.push(snapshot);
                });
            }
            if (this.q.status == "2") {
              this.listOrderChartW.forEach(function (value) {
                value.METKHOITICHLUY = 0;
              });
              if (data != null) {
                let that1 = this;
                data.forEach(function (value) {
                  that1.listOrderChartW.find(
                    (item) => item.NGAYDATHANG == value.NGAYDATHANG
                  ).METKHOITICHLUY = value.METKHOITICHLUY;
                  sum += value.METKHOITICHLUY;
                });
              }
              this.barChartData = [
                {
                  data: this.listOrderChartW.map((x) => x.METKHOITICHLUY),
                  label:
                    this.translate.instant("DASHBOARD.TONG_KHOI_LUONG") +
                    ": " +
                    sum.toFixed(1).toString() +
                    "m3",
                },
              ];
              if (this.barChartLabels.length !== 0) {
                this.barChartLabels.splice(0, 99);
              }
              let that = this;
              this.listOrderChartW
                .map((x) => x.NGAYDATHANG)
                .forEach(function (snapshot) {
                  that.barChartLabels.push(snapshot);
                });
            }
            if (this.q.status == "3") {
              this.listOrderChartW.forEach(function (value) {
                value.METKHOITICHLUY = 0;
              });
              if (data != null) {
                let that1 = this;
                data.forEach(function (value) {
                  that1.listOrderChartW.find(
                    (item) => item.NGAYDATHANG == value.NGAYDATHANG
                  ).METKHOITICHLUY = value.METKHOITICHLUY;
                  sum += value.METKHOITICHLUY;
                });
              }
              this.barChartData = [
                {
                  data: this.listOrderChartW.map((x) => x.METKHOITICHLUY),
                  label:
                    this.translate.instant("DASHBOARD.TONG_KHOI_LUONG") +
                    ": " +
                    sum.toFixed(1).toString() +
                    "m3",
                },
              ];
              if (this.barChartLabels.length !== 0) {
                this.barChartLabels.splice(0, 99);
              }
              let that = this;
              this.listOrderChartW
                .map((x) => x.NGAYDATHANG)
                .forEach(function (snapshot) {
                  that.barChartLabels.push(snapshot);
                });
            }
            if (this.q.status == "4") {
              this.listOrderChartD.forEach(function (value) {
                value.METKHOITICHLUY = 0;
              });
              if (data != null) {
                let that1 = this;
                data.forEach(function (value) {
                  that1.listOrderChartD.find(
                    (item) => item.NGAYDATHANG == value.NGAYDATHANG
                  ).METKHOITICHLUY = value.METKHOITICHLUY;
                  sum += value.METKHOITICHLUY;
                });
              }
              this.barChartData = [
                {
                  data: this.listOrderChartD.map((x) => x.METKHOITICHLUY),
                  label:
                    this.translate.instant("DASHBOARD.TONG_KHOI_LUONG") +
                    ": " +
                    sum.toFixed(1).toString() +
                    "m3",
                },
              ];
              if (this.barChartLabels.length !== 0) {
                this.barChartLabels.splice(0, 99);
              }
              let that = this;
              this.listOrderChartD
                .map((x) => x.NGAYDATHANG)
                .forEach(function (snapshot) {
                  that.barChartLabels.push(snapshot);
                });
            }
            if (this.q.status == "5") {
              this.listOrderChartD.forEach(function (value) {
                value.METKHOITICHLUY = 0;
              });
              if (data != null) {
                let that1 = this;
                data.forEach(function (value) {
                  that1.listOrderChartD.find(
                    (item) => item.NGAYDATHANG == value.NGAYDATHANG
                  ).METKHOITICHLUY = value.METKHOITICHLUY;
                  sum += value.METKHOITICHLUY;
                });
              }
              this.barChartData = [
                {
                  data: this.listOrderChartD.map((x) => x.METKHOITICHLUY),
                  label:
                    this.translate.instant("DASHBOARD.TONG_KHOI_LUONG") +
                    ": " +
                    sum.toFixed(1).toString() +
                    "m3",
                },
              ];
              if (this.barChartLabels.length !== 0) {
                this.barChartLabels.splice(0, 99);
              }
              let that = this;
              this.listOrderChartD
                .map((x) => x.NGAYDATHANG)
                .forEach(function (snapshot) {
                  that.barChartLabels.push(snapshot);
                });
            }
          }
        },
        (err) => {
          console.log("Error: connect to API");
        }
      );
  }
  GetListCompany() {
    this.http
      .get(
        "/api/company/GetByPage?page=1&query=1=1&order_by=",
        this.httpOptions
      )
      .subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.listCompany = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        }
      );
  }
  GetListBranchSearchStart() {
    this.http
      .get(
        "/api/branch/GetByPage?page=1&query=CompanyId = " +
          this.companyId +
        " &order_by=",
        this.httpOptions
      )
      .subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.listBranchSearch = res["data"];
            let localtramtron = localStorage.getItem("BranchId");
            if (localtramtron != "undefined") {
              let ids = localtramtron.split(",").map(Number);
              this.listBranchSearch = this.listBranchSearch.filter((x) =>
                ids.includes(x.BranchId)
              );
            }
          if (localtramtron != "undefined") {
            let ids = localtramtron.split(",").map(Number);
            this.listBranchSearch = this.listBranchSearch.filter(
              (x) => ids.includes(x.BranchId)
            );
          }
          }
        },
        (err) => {
          console.log("Error: connect to API");
        }
      );
  }
  GetListBranchCTCon() {
    this.http
      .get("/api/Order/GetByUser?id=" + this.UserId, this.httpOptions)
      .subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.listBranchSearch = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        }
      );
  }
  GetListBranchSearch() {
    this.http
      .get(
        "/api/branch/GetByPage?page=1&query=CompanyId = " +
          this.companyselectsearch +
          " &order_by=",
        this.httpOptions
      )
      .subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.listBranchSearch = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        }
      );
  }
  clickd() {
    localStorage.setItem("getlinkd", "1");
  }
  //
  QueryChanged() {
    let query = "";
    let querychar = "";
    if (this.q.status != undefined && this.q.status != "") {
      if (this.q.status == "0") {
        let today = new Date();
        this.q.tungay = this.datepipe.transform(today, "yyyy-MM-dd");
        this.q.denngay = this.datepipe.transform(today, "yyyy-MM-dd");
        // let today = new Date().toISOString().substr(0, 10);

        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
      if (this.q.status == "1") {
        let today = new Date();
        let yesterdayAM = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 1
        );
        let yesterdayPM = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 1,
          23,
          59,
          59
        );
        this.q.tungay = this.datepipe.transform(yesterdayAM, "yyyy-MM-dd");
        this.q.denngay = this.datepipe.transform(yesterdayPM, "yyyy-MM-dd");
        // let today = new Date().toISOString().substr(0, 10);

        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
      if (this.q.status == "2") {
        let today = new Date();
        this.q.tungay = this.datepipe.transform(
          new Date(today.setDate(today.getDate() - today.getDay() + 1)),
          "yyyy-MM-dd"
        );
        this.q.denngay = this.datepipe.transform(
          new Date(today.setDate(today.getDate() - today.getDay() + 7)),
          "yyyy-MM-dd"
        );
        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
      if (this.q.status == "3") {
        let today = new Date();
        this.q.tungay = this.datepipe.transform(
          new Date(today.setDate(today.getDate() - today.getDay() + 1 - 7)),
          "yyyy-MM-dd"
        );
        let today1 = new Date();
        this.q.denngay = this.datepipe.transform(
          new Date(today1.setDate(today1.getDate() - today1.getDay())),
          "yyyy-MM-dd"
        );
        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
      if (this.q.status == "4") {
        var today2 = new Date();
        var lastDayOfMonth = new Date(
          today2.getFullYear(),
          today2.getMonth() - 1,
          1
        );
        this.q.tungay = this.datepipe.transform(lastDayOfMonth, "yyyy-MM-dd");

        var today3 = new Date();
        var lastDayOfMonth1 = new Date(
          today3.getFullYear(),
          today3.getMonth(),
          1
        );
        var lastDayOfMonth2 = new Date(
          lastDayOfMonth1.getFullYear(),
          lastDayOfMonth1.getMonth(),
          lastDayOfMonth1.getDate() - 1
        );
        this.q.denngay = this.datepipe.transform(lastDayOfMonth2, "yyyy-MM-dd");
        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
      if (this.q.status == "5") {
        var today2 = new Date();
        var lastDayOfMonth = new Date(
          today2.getFullYear(),
          today2.getMonth(),
          1
        );
        this.q.tungay = this.datepipe.transform(lastDayOfMonth, "yyyy-MM-dd");

        var today3 = new Date();
        var lastDayOfMonth1 = new Date(
          today3.getFullYear(),
          today3.getMonth() + 1,
          1
        );
        var lastDayOfMonth2 = new Date(
          lastDayOfMonth1.getFullYear(),
          lastDayOfMonth1.getMonth(),
          lastDayOfMonth1.getDate() - 1
        );
        this.q.denngay = this.datepipe.transform(lastDayOfMonth2, "yyyy-MM-dd");
        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
    }

    if (this.role == 3) {
      this.paging.CompanyId = this.companyId;
    } else {
      if (this.q.CompanyId != undefined) {
        this.paging.CompanyId = this.q.CompanyId;
      } else {
        this.paging.CompanyId = 0;
      }
    }

    if (this.q.Branchlist != undefined) {
      this.paging.Branchid = "";
      this.q.Branchlist.forEach((item, index) => {
        if (item != "") {
          this.paging.Branchid = this.paging.Branchid + item + ",";
        }
      });
    } else {
      this.paging.Branchid = "";
      if (this.role != 1) {
        this.listBranchSearch.forEach((item, index) => {
          if (item != "") {
            this.paging.Branchid =
              this.paging.Branchid + item["BranchId"] + ",";
          }
        });
      }
    }
    if (query == "") {
      this.paging.query = "1=1";
    } else {
      this.paging.query = query;
    }

    if (querychar == "") {
      this.paging.querychar = "1=1";
    } else {
      this.paging.querychar = querychar;
    }

    localStorage.setItem("thoigiand", this.q.status);
    localStorage.setItem("congtyd", this.q.CompanyId);
    localStorage.setItem("tramtrond", this.q.Branchlist);
    this.GetListOrder();
    this.GetListOrderDH();
    this.GetListOrderChart();
  }
  //
  QueryChangedCompany() {
    let query = "";
    let querychar = "";
    if (this.q.status != undefined && this.q.status != "") {
      if (this.q.status == "0") {
        let today = new Date();
        this.q.tungay = this.datepipe.transform(today, "yyyy-MM-dd");
        this.q.denngay = this.datepipe.transform(today, "yyyy-MM-dd");
        // let today = new Date().toISOString().substr(0, 10);

        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
      if (this.q.status == "1") {
        let today = this.datepipe.transform(new Date(), "yyyy-MM-dd");
        this.q.tungay = today;
        this.q.denngay = today;
        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
      if (this.q.status == "2") {
        let today = new Date();
        this.q.tungay = this.datepipe.transform(
          new Date(today.setDate(today.getDate() - today.getDay() + 1)),
          "yyyy-MM-dd"
        );
        this.q.denngay = this.datepipe.transform(
          new Date(today.setDate(today.getDate() - today.getDay() + 7)),
          "yyyy-MM-dd"
        );
        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
      if (this.q.status == "3") {
        let today = new Date();
        this.q.tungay = this.datepipe.transform(
          new Date(today.setDate(today.getDate() - today.getDay() + 1 - 7)),
          "yyyy-MM-dd"
        );
        let today1 = new Date();
        this.q.denngay = this.datepipe.transform(
          new Date(today1.setDate(today1.getDate() - today1.getDay())),
          "yyyy-MM-dd"
        );
        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
      if (this.q.status == "4") {
        var today2 = new Date();
        var lastDayOfMonth = new Date(
          today2.getFullYear(),
          today2.getMonth(),
          2
        );
        this.q.tungay = this.datepipe.transform(lastDayOfMonth, "yyyy-MM-dd");

        var today3 = new Date();
        var lastDayOfMonth1 = new Date(
          today3.getFullYear(),
          today3.getMonth() + 1,
          1
        );
        this.q.denngay = this.datepipe.transform(lastDayOfMonth1, "yyyy-MM-dd");
        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
      if (this.q.status == "5") {
        var today2 = new Date();
        var lastDayOfMonth = new Date(
          today2.getFullYear(),
          today2.getMonth(),
          1
        );
        this.q.tungay = this.datepipe.transform(lastDayOfMonth, "yyyy-MM-dd");

        var today3 = new Date();
        var lastDayOfMonth1 = new Date(
          today3.getFullYear(),
          today3.getMonth() + 1,
          1
        );
        this.q.denngay = this.datepipe.transform(today3, "yyyy-MM-dd");
        query +=
          "( sa.NGAYDATHANG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND sa.NGAYDATHANG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
        querychar +=
          "( tr.GIOXONG >= convert(datetime ,'" +
          this.q.tungay +
          " 00:00:00.000') AND tr.GIOXONG <= convert(datetime ,'" +
          this.q.denngay +
          " 23:59:59.999') )";
      }
    }

    if (this.q.CompanyId != undefined) {
      this.companyselectsearch = this.q.CompanyId;
      this.listBranchSearch = [];
      this.q.BranchId = null;

      this.GetListBranchSearch();
      this.paging.CompanyId = this.q.CompanyId;
    } else {
      this.paging.CompanyId = 0;
    }
    if (this.q.Branchlist != undefined) {
      this.paging.Branchlist = this.q.Branchlist;
    } else {
      this.paging.Branchid = "";
      if (this.role != 1) {
        this.listBranchSearch.forEach((item, index) => {
          if (item != "") {
            this.paging.Branchid =
              this.paging.Branchid + item["BranchId"] + ",";
          }
        });
      }
    }
    if (query == "") {
      this.paging.query = "1=1";
    } else {
      this.paging.query = query;
    }

    if (querychar == "") {
      this.paging.querychar = "1=1";
    } else {
      this.paging.querychar = querychar;
    }
    localStorage.setItem("thoigiand", this.q.status);
    localStorage.setItem("congtyd", this.q.CompanyId);
    localStorage.setItem("tramtrond", this.q.Branchlist);
    this.GetListOrder();
    this.GetListOrderDH();
    this.GetListOrderChart();
  }
}
